import React, { useState, useEffect } from 'react';

//React components
import Header1 from '../elements/Header1';
import Header6 from '../elements/Header6';
import TextInput from '../components/inputs/TextInput';
import Checkbox from '../components/inputs/Checkbox';
import PrimaryButton from '../components/buttons/PrimaryButton';
import LanguageSelect from '../components/inputs/LanguageSelect';

//Modules
import { registerUser } from '../services/auth';

//Services
import { withRouter } from 'react-router-dom'

// Translation
import { useTranslation, Trans } from 'react-i18next';

const CreateAccountPage = ({ history }) => {
    //State
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [tos, setTos] = useState(false);
    const [error, setError] = useState(null);

    //Translation
    const { t } = useTranslation();

    //Effect
    useEffect(() => {
        //If the user already has all the required tokens, redirect to home
        if (
            localStorage.getItem('accessToken') &&
            localStorage.getItem('refreshToken') &&
            localStorage.getItem('idToken')
        ) history.push('/');
    }, [history]);

    //Functions
    const submitForm = () => {
        try {
            registerUser(name, surname, email, password)
                .then(result => {
                    if (result.status === 201) {
                        setError(null);
                        history.push('/registration-success');
                    }
                })
                .catch(e => {
                    console.error(e);
                    if (e) {
                        if (e.data) {
                            setError(e.data)
                        }
                    }
                })
        }
        catch (e) {
            console.log(e);
        }
    }

    return (
        <div className={'flex w-screen h-screen sm:flex-col-reverse sm:h-auto'}>
            <div className={'bg-white px-24 md:px-6 min-h-screen w-2/5 md:w-3/6 flex items-center justify-center sm:w-full relative'}>
            <LanguageSelect />
                <div>
                    <h1 className={'text-indigo-100 pt-12 sm:pt-0'} style={{ fontSize: 72, fontWeight: 'bold', textAlign: 'center' }}>kelvyn</h1>
                    <Header1 color={'text-indigo-100'}>{t('portal')}</Header1>
                    <TextInput
                        value={name}
                        placeholder={t('login.create.firstName')}
                        inputChange={(text) => setName(text)}
                        error={error ? error.error ? error.error.name ? t('login.create.error.name') : false : false : false}
                    />

                    <TextInput
                        value={surname}
                        placeholder={t('login.create.lastName')}
                        inputChange={(text) => setSurname(text)}
                        error={error ? error.error ? error.error.family_name ? t('login.create.error.lastName') : false : false : false}
                    />

                    <TextInput
                        value={email}
                        placeholder={t('login.create.email')}
                        inputChange={(text) => setEmail(text)}
                        error={error ? error.error ? error.error.email ? t('login.login.error.email') : false : false : false}
                    />

                    <TextInput
                        value={password}
                        placeholder={t('login.create.password')}
                        type={'password'}
                        inputChange={(text) => setPassword(text)}
                        error={error ? error.error ? error.error.password ? t('login.login.error.password') : false : false : false}
                    />

                    <Checkbox
                        status={tos}
                        inputChange={(status) => { setTos(status) }}
                    >
                        <Trans i18nKey="login.create.terms">
                            I accept the <a className={'text-indigo-100 underline'} href={'/'}>Terms of Use</a> and <a className={'text-indigo-100 underline'} href={'/'}>Privacy Policy*</a>.
                        </Trans>
                    </Checkbox>

                    {error ? error.error ? Object.values(error.error).length > 0 ?
                        Object.entries(error.error).map((value) => {
                            let translationsArray = []
                            value[1].map((value) => {
                                switch (value) {
                                    case 'Password did not conform with policy: Password must have a symbol character, a uppercase, a lowercase and a number':
                                        translationsArray.push('login.login.error.passwordConform')
                                        break;
                                    case 'The password should be at least eight characters!':
                                        translationsArray.push('login.login.error.passwordLength')
                                        break;
                                    case 'Not a valid email address.':
                                        translationsArray.push('login.login.error.notValidEmail')
                                        break;
                                    case 'An account with the given email already exists.':
                                        translationsArray.push('login.login.error.auth')
                                        break;
                                    default:
                                        translationsArray.push('login.login.error.errorOccured')
                                        break;
                                }
                                return value;
                            })
                            return translationsArray.map((translation) => {
                                return (
                                  <div className={'flex flex-row items-center mt-3'}>
                                      <div className={'items-center'}>
                                          <svg
                                            className={'h-6 text-orange-100'}
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                          >
                                              <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                                          </svg>

                                      </div>
                                      <Header6 textAlign={'left'} color={'orange-100 ml-1'}>{t(translation)}</Header6>
                                  </div>
                                )
                            })
                        })

                        : false : false : false
                    }

                    <PrimaryButton
                        text={t('login.create.createAccount')}
                        disabled={!name || !surname || !email || !password || !tos}
                        submit={submitForm}
                    />

                    <div className={`flex justify-center`}>
                        <p onClick={() => history.push('/login')} className={`underline inline-block cursor-pointer`}>
                            <Header6 additionalClass={'text-center'} color={'indigo-100'}>{t('login.create.back')}</Header6>
                        </p>
                    </div>

                </div>
            </div >

            <div className={'h-screen w-3/5 md:w-3/6 sm:h-16 sm:w-full'}>
                <img src={require('../assets/images/iceberg.jpg')} alt={'Iceberg background'} className={'h-screen w-full object-cover sm:h-16 sm:object-none'} />
            </div>
        </div >
    )
}

export default withRouter(CreateAccountPage);
