//Redux
import { combineReducers } from 'redux';

//Reducers
import alarmReducer from './alarm';
import haccpReducer from './haccp';
import sensorReducer from './sensor';

const rootReducer = combineReducers({
    alarm: alarmReducer,
    haccp: haccpReducer,
    sensor: sensorReducer,
});

export default rootReducer;