import API from '../utils/APIDomain';

export const getAllLocations = () => {
    return new Promise(async (resolve, reject) => {
        API.get('/device/locations')
            .then(res => {
                if (res.data && res.data.results)
                    resolve(res.data.results);
            })
            .catch(e => {
                reject(e.response);
            })
    })
}

export const getAllSensors = () => {
    return new Promise(async (resolve, reject) => {
        API.get('/device/sensors')
            .then(res => {
                if (res.data && res.data.results)
                    resolve(res.data.results);
            })
            .catch(e => {
                reject(e.response);
            })
    })
}

export const postLocationGroup = (data) => {
    return new Promise(async (resolve, reject) => {
        API.post('device/locations', data)
        .then(res => {
            resolve(res);
        })
        .catch(e => {
            reject(e.response);
        })
    })
}