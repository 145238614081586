import axios from "axios";

const DOMAIN_LINK = process.env.REACT_APP_API_LINK_DOMAIN;

const axiosInstance = axios.create({
    baseURL: DOMAIN_LINK,
    responseType: 'json',
    headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
        Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    },
});

/*Add request interceptor for initial 401 unauth check*/
axiosInstance.interceptors.request.use((config) => {
    if (localStorage.getItem('accessToken')) {
        config.headers.Authorization =
          'Bearer ' + localStorage.getItem('accessToken');
    }
    return config;
});

/* Response interceptor is added for domain apis to refresh token on receiving token expired error
 * It will catch each apis response and after refreshing the token, it will fire the request again
 * So the pages can have refreshed data
 * */
axiosInstance.interceptors.response.use(
  (response) => {
      return response;
  },
  function (error) {
      const originalRequest = error.config;
      let refreshToken = localStorage.getItem('refreshToken');

      if (refreshToken && error.response.status === 401) {
          return axios
            .post(process.env.REACT_APP_API_LINK + `/refresh_token`, {
                refresh_token: refreshToken,
            })
            .then((res) => {
                if (res.status === 200) {
                    localStorage.setItem(
                      'accessToken',
                      res.data.credentials.access_token
                    );
                    localStorage.setItem(
                      'refreshToken',
                      res.data.credentials.refresh_token
                    );
                    localStorage.setItem('idToken', res.data.credentials.id_token);
                    error.config.headers.Authorization =
                      'Bearer ' + res.data.credentials.access_token;
                    return axiosInstance.request(originalRequest);
                }
            });
      }
      return Promise.reject(error);
  }
);

export default axiosInstance;
