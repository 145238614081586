import React from 'react';

const Header1 = (props) => {
    return (
        <h1
            className={`${props.textAlign ? props.textAlign : 'text-center'} ${props.disabled ? 'text-text-onyx opacity-38' : props.color ? props.color : 'text-text-onyx'} font-bold font-sans text-4xl`}
        >
            {props.children}
        </h1>
    )
}

export default Header1;
