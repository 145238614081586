import React from 'react';

import BellIcon from '../../assets/icons/bell.svg';
import BatteryIcon from '../../assets/icons/battery.svg';
// import BatteryLowIcon from '../../assets/icons/battery-low.svg';
import ElipsesIcon from '../../assets/icons/ellipses.svg';
// import NoConnectionIcon from '../../assets/icons/no-connection.svg';
// import WiFiIcon from '../../assets/icons/wifi.svg';
// import AboveThresholdIcon from '../../assets/icons/above-threshold.svg';
// import BelowThresholdIcon from '../../assets/icons/below-threshold.svg';
// import InformationIcon from '../../assets/icons/information.svg';

const Card = ({
  temperature,
  sensorId,
  location,
  group,
  battery,
  sensorName,
  threshold,
}) => {
  return (
    <div
      className={`p-5 sm:py-4 sm:px-4 w-full overflow-x-hidden shadow mt-10 rounded-lg`}
      style={{ backgroundColor: '#fdfdfe' }}
    >
      <div className={`flex-column`}>
        <div className={`flex flex-row justify-end align-items-center`}>
          <img
            src={BatteryIcon}
            alt={`Silent notifications icon`}
            className={`w-4 h-4 float-right cursor-pointer`}
          />
          {battery && <span className="text-xs ml-1"> {battery}% </span>}

          <img
            src={ElipsesIcon}
            alt={`Silent notifications icon`}
            style={{ filter: 'grayscale(100%)' }}
            className={`ml-2 w-4 h-4 p-1 rounded-full bg-indigo-6 float-right`}
          />
        </div>
        <div className={`flex flex-row items-center`}>
          <div className={`pr-1`}>
            <img
              src={BellIcon}
              alt={`Silent notifications icon`}
              className={`w-4 h-4 float-right cursor-pointer`}
            />
          </div>
          <div className={`text-indigo-100 font-bold`} style={{ fontSize: 15 }}>
            {sensorName}
          </div>
        </div>
        <div className={`text-text-onyx opacity-60`} style={{ fontSize: 12 }}>
          {location}, {group}
        </div>

        <div
          className={`text-magenta-100 opacity-87 flex flex-row justify-center`}
          style={{ fontSize: 40 }}
        >
          {temperature ? <>{temperature}°C</> : '-'}
        </div>
        <div
          className={`text-magenta-100 opacity-87 flex flex-row justify-center`}
          style={{ fontSize: 14 }}
        >
          {threshold ? (
            <>
              Threshold:{' '}
              {Math.trunc(threshold[0])} - {Math.trunc(threshold[1])}
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
