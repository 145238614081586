import React from 'react';

const Body = (props) => {
    return (
        <h1
            className={`${props.disabled ? 'text-gray-500' : props.color ? props.color : 'text-blue-500'} font-normal font-sans text-base h-5 pb-16 sm:pb-20 md:pb-16`} >
            {props.children}
        </h1>
    )
}

export default Body;
