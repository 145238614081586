const initialState = {
  sensorPageRefresh: false
}
const sensorReducer = (state = initialState, action) => {
  if (action.type === 'SET_CREATED_SENSOR') {
    return Object.assign({}, state, {
      sensorPageRefresh: action.sensorPageRefresh})
  }
  else return state;
}

export default sensorReducer;