import React, { useState, useEffect, useRef } from 'react';

//Components
import Dropdown from '../components/inputs/Dropdown';
import MonthPicker from '../components/selectionControls/MonthPicker';
import PrimaryButton from '../components/buttons/PrimaryButton';
import HeatMap from '../components/charts/HeatMap/HeatMap';
import Table from '../components/table/Table';
import HaccpSettings from '../components/modals/HaccpSettings';
import HaccpEditSettings from '../components/modals/HaccpEditSettings';
import HeatmapDetails from '../components/modals/HeatmapDetails';

//Elements
import Header2 from '../elements/Header2';
import Header4 from '../elements/Header4';
import Header6 from '../elements/Header6';
import Loader from '../elements/Loader';

//Assets
import DownloadIcon from '../assets/icons/download.svg';
import FilterIcon from '../assets/icons/settings.svg';
import CloseIcon from '../assets/icons/close-tooltip.svg';
import SettingsButton from '../assets/icons/ellipses.svg';

//Modules
import moment from 'moment';
import uuid from 'react-uuid';

//PDF Report
import domtoimage from 'dom-to-image';
import { jsPDF } from "jspdf";

//Services
import {
    getAllLocations,
    getAllSensors
} from '../services/location';
import {
    prepareMeasurementTableHeatMapData,
    newPrepareHeatmap
} from '../services/heatmap';
import {
    getMeasurementListBySensorId,
    getMeasurementListByMonth,
    getRuleDetails,
    getRuleBySensorId,
    getLastMeasurement,
    postRuleCreate
} from '../services/haccp';

// history
import { withRouter } from 'react-router-dom'

// Translation
import { useTranslation } from 'react-i18next';

//Redux
import { useSelector } from 'react-redux';

const HaccpPage = ({ history }) => {
    let date = new Date();
    //State
    const [availableLocations, setAvailableLocations] = useState([]);
    const [availableGroups, setAvailableGroups] = useState([]);
    const [availableSensors, setAvailableSensors] = useState([]);
    const [location, setLocation] = useState('');
    const [group, setGroup] = useState('');
    const [sensor, setSensor] = useState({
        label: 'All Sensors',
        value: 'All Sensors',
      });
    const [month, setMonth] = useState(date.getMonth() + 1);
    const [year, setYear] = useState(date.getFullYear());
    const [showMobileFilters, setShowMobileFilters] = useState(false);
    const [pdfLoading, setPdfLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [showEditSettingsModal, setShowEditSettingsModal] = useState(false);
    const [selectedSensor, setSelectedSensor] = useState(null);
    const [selectedRule, setSelectedRule] = useState(null);
    const [lastMeasurement, setLastMeasurement] = useState(null);
    // const [showHeatmapDetails, setShowHeatmapDetails] = useState(false);
    const [noData, setNoData] = useState(false);

    //Redux
    const editedSensorName = useSelector(state => state.haccp.editedSensorName);
    const editedSensorId = useSelector(state => state.haccp.editedSensorId);
    const editedSensorThreshold = useSelector(state => state.haccp.editedSensorThreshold);
    const editedSensorMeasurementTimes = useSelector(state => state.haccp.editedSensorMeasurementTimes);
    const showCommentModal = useSelector(state => state.haccp.commentModalOpened);
    const commentModalId = useSelector(state => state.haccp.commentModalId);

    //Translation
    const { t } = useTranslation();

    //Refs
    const pdfCanvas = useRef();

    //Constants
    const heatMapTableHeaders = ['Date', 'Time', 'Alarm Type', 'Comment'];

    //Effect 
    useEffect(() => {
        getAllLocations().then(res => {
            if (res.length > 0) {
                let tempLocations = [];
                res.map(location => {
                    tempLocations.push(
                        {
                            id: location.id,
                            value: location.id,
                            label: location.name,
                            companyId: location.company_id,
                            groups: location.location_groups
                        }
                    );
                    return tempLocations;
                })
                setAvailableLocations(tempLocations);
            }
        }).catch(e => {
            console.log(e);
        });
        getAllSensors().then(res => {
            if (res.length > 0) {
                res.map(async (sensor) => {
                    const sensorList = await getMeasurementListByMonth(sensor.sensor_id, month, year)
                    const temp = (
                        {
                            id: sensor.id,
                            value: sensor.id,
                            label: sensor.name,
                            companyId: sensor.company_id,
                            group: sensor.location_group,
                            location: sensor.location,
                            active: sensor.is_active,
                            sensorId: sensor.sensor_id,
                            rule: await getRuleBySensorId(sensor.sensor_id),
                            preparedMeasurements: await newPrepareHeatmap(sensorList),
                            preparedMeasurementsTable: (await prepareMeasurementTableHeatMapData(sensorList)).slice(0, 5)
                        }
                    );
                    setAvailableSensors(prev=> [...prev, temp]);
                })
                setLoading(false);
            } else {
                setLoading(false);
                setNoData(true);
            }
        }).catch(e => {
            console.log(e);
            setLoading(false)
        });
        // eslint-disable-next-line 
    }, [month, year,]);

    useEffect(() => {
        if (location.groups && location.groups.length > 0) {
            let tempGroups = [];
            location.groups.map(group => {
                tempGroups.push(
                    {
                        id: group.id,
                        value: group.id,
                        label: group.name,
                        companyId: group.companyId,
                        location: group.location
                    }
                );
                return tempGroups;
            });
            setAvailableGroups(tempGroups);
        }
    }, [location, availableSensors]);

    //Functions
    const handleDownload = () => {
        if (!pdfLoading) {
            generatePdfReport();
        }
    }

    const generatePdfReport = () => {
        const doc = new jsPDF();

        generateSensorScreenshots()
            .then(images => {
                if (images.length > 0) {
                    images.map((image, index) => {
                        if (index === 0) {
                            doc.text(`Sensor ${index + 1}`, 10, 10);
                            doc.addImage(image, 10, 10, 200, 115);
                        }
                        else {
                            doc.addPage();
                            doc.text(`Sensor ${index + 1}`, 10, 10);
                            doc.addImage(image, 10, 10, 200, 115);
                        }
                        if (index === images.length - 1) {
                            doc.save(`${uuid()}.pdf`);
                            setPdfLoading(false);
                        }
                        return image;
                    })
                }
            })
            .catch(e => {
                console.log(e);
                setPdfLoading(false);
            })
    }

    function generateSensorScreenshots() {
        return new Promise((resolve, reject) => {
            const canvas = pdfCanvas.current;
            const canvasLength = canvas.childElementCount;
            const canvasChildren = canvas.childNodes
            let result = [];

            if (canvas.childElementCount > 0) {
                for (let i = 0; i < canvasLength; i++) {
                    domtoimage.toPng(canvasChildren[i])
                        .then(function (dataUrl) {
                            result.push(dataUrl);

                            if (result.length === canvasLength) {
                                resolve(result);
                            }
                        })
                        .catch(function (error) {
                            console.error('Generating image from dom failed', error);
                            setPdfLoading(false);
                        })
                }
            }
            else {
                setPdfLoading(false);
                reject(`Canvas doesn't have children`);
            }
        })
    }

    //Functions
    const changeLocation = (value) => {
        setLocation(value);
        setAvailableGroups([]);
    }

    const changeGroup = (value) => {
        setGroup(value);
    }

    const changeSensor = (value) => {
        setSensor(value);
    }

    const changeMonth = (selection) => {
        setMonth(moment(new Date(`01 ${selection}`)).month() + 1);
        setYear(moment(new Date(`01 ${selection}`)).year());
    }

    const handleOpenSettings = (sensor) => {
        setSelectedSensor(sensor);
        setShowSettingsModal(true);
        getMeasurementListBySensorId(sensor.sensorId).then(res => {
            if (res.data) {
                if (res.data.length > 0) {
                    const tempRule = sensor.rule.id;
                    getRuleDetails(tempRule)
                        .then(res => {
                            if (res.data) {
                                setSelectedRule(res.data);
                            }
                        })
                        .catch(e => {
                            console.log(e);
                            //Create new Rule
                            postRuleCreate(sensor.sensorId, true, [-20.00, 65.00], ['12:00'])
                                .then(res => {
                                    if (res && res.data) {
                                        setSelectedRule(res.data);
                                    }
                                })
                        })
                    getLastMeasurement(sensor.sensorId, res.data.count).then(res => {
                        if (res && res.data && res.data.length > 0) {
                            setLastMeasurement(res.data[res.data.length - 1]);
                        }
                    });
                }
            }
        })
    }

    const handleCloseSettings = () => {
        setSelectedSensor(null);
        setShowSettingsModal(false);
    }

    const handleOpenEditSettings = () => {
        setShowSettingsModal(false);
        setShowEditSettingsModal(true);
    }

    const handleCloseEditSettings = () => {
        setShowEditSettingsModal(false);
        setShowSettingsModal(true);
    }

    // const handleOpenHeatmapDetails = () => {
    //     setShowHeatmapDetails(true);
    // }

    // const handleCloseHeatmapDetails = () => {
    //     setShowHeatmapDetails(false);
    // }

    //Components
    const ShowSensor = (sens, index) => {
        return (
            <div key={`sensor_${index}`} className={`bg-text-white p-12 shadow mt-10 rounded-lg`}>
                <img
                    src={SettingsButton}
                    alt={'Addittional settings button'}
                    className={`w-6 h-6 p-1 rounded-full bg-indigo-6 float-right cursor-pointer`}
                    onClick={() => handleOpenSettings(sens)}
                />

                <Header4 textAlign={'left'} color={'indigo-100'}>
                    {sens.sensorId === editedSensorId
                        ? editedSensorName
                        : sens.label}
                </Header4>
                <Header6 textAlign={'left'} color={'text-onyx opacity-60 mt-1'}>
                    {sens && sens.location && sens.location.name}
                    {sens.rule ?
                        ` / Threshold: ${sens.sensorId === editedSensorId
                            ? editedSensorThreshold[0]
                            : sens.rule.threshold[0]
                        } — ${sens.sensorId === editedSensorId
                            ? editedSensorThreshold[1]
                            : sens.rule.threshold[1]
                        }°`
                        : false
                    }
                </Header6>

                <HeatMap
                    sampleHours={
                        sens.sensorId === editedSensorId
                            ? editedSensorMeasurementTimes
                            : sens.rule.sample_hours
                    }
                    measurement={sens.preparedMeasurements}
                    threshold={
                        sens.sensorId === editedSensorId
                            ? editedSensorThreshold
                            : sens.rule.threshold
                    }
                    month={month}
                    year={year}
                />

                <Header4 textAlign={'left mt-12'} color={'indigo-100'}>{t('haccp.haccp.comments')}</Header4>
                <Table headers={heatMapTableHeaders} rows={sens.preparedMeasurementsTable} sign={"Sensor"} size={'big'}></Table>
            </div>
        )
    }

    return (
        <>
            <HaccpSettings
                show={showSettingsModal}
                onClose={() => handleCloseSettings()}
                onEdit={() => handleOpenEditSettings()}
                sensor={selectedSensor}
                rule={selectedRule}
                lastMeasurement={lastMeasurement}
            />
            <HaccpEditSettings
                show={showEditSettingsModal}
                onClose={() => handleCloseEditSettings()}
                sensor={selectedSensor}
                rule={selectedRule}
                lastMeasurement={lastMeasurement}
            />
            <HeatmapDetails
                show={showCommentModal}
                id={commentModalId}
                onClose={() => console.log('Comment modal closed')}
            />
            <div className={`py-10 px-16 sm:py-4 sm:px-4 w-full`} style={{ backgroundColor: '#fdfdfe' }}>
                <div className={`flex md:justify-between`}>
                    <Header2>{t('menu.haccp')}</Header2>
                    <div className={`w-3/4 grid grid-cols-4 gap-3 ml-8 md:hidden sm:hidden`}>
                        <Dropdown
                            placeholderText={t('haccp.haccp.location')}
                            options={availableLocations}
                            onValueChange={value => changeLocation(value)}
                        />
                        <Dropdown
                            placeholderText={t('haccp.haccp.group')}
                            options={availableGroups}
                            onValueChange={value => changeGroup(value)}
                        />
                        <Dropdown
                            placeholderText={t('haccp.haccp.sensor')}
                            options={[{ label: 'All Sensors', value: 'All Sensors' }].concat(availableSensors)}
                            onValueChange={value => changeSensor(value)}
                        />
                        <MonthPicker
                            disableFuture
                            placeholder={t('haccp.haccp.month')}
                            onValueChange={(selection) => changeMonth(selection)}
                        />
                    </div>
                    <div className={'flex ml-auto'}>
                        <div
                            className={`w-12 h-12 rounded-full bg-indigo-6 hidden sm:flex md:flex justify-center align-center ml-3 mr-3 shadow cursor-pointer`}
                            onClick={() => setShowMobileFilters(true)}
                        >
                            <img src={FilterIcon} alt={'Settings button'} className={`h-full w-4`} />
                        </div>
                        <div
                            className={`w-12 h-12 rounded-full bg-indigo-6 flex justify-center items-center shadow cursor-pointer`}
                            onClick={() => { setPdfLoading(true); handleDownload(); }}
                        >
                            {pdfLoading ?
                                <Loader show className={'object-center'} />
                                : <img src={DownloadIcon} alt={'Download button'} className={`h-full w-4`} />
                            }
                        </div>
                    </div>
                </div>
                <div ref={pdfCanvas}>
                    {loading === true ? <div className={'flex justify-center h-screen items-center'}> <Loader show size={'w-20 h-20'} /> </div> :

                        noData === false ? <>
                            {
                                availableSensors.length > 0 ?
                                    availableSensors.map((sens, index) => (
                                        

                                        (sensor.value === 'All Sensors') ?
                                            ShowSensor(sens, index)

                                            : (sensor.value && sensor.value !== 'All Sensors') ?
                                                (sensor.value === sens.id) ?
                                                    ShowSensor(sens, index)
                                                    : false

                                                : (group.value && !sensor.value) ?
                                                    sens.group.id === group.value ?
                                                        ShowSensor(sens, index)
                                                        : false

                                                    : (location.value && !group.value && !sensor.value) ?
                                                        sens.location.id === location.value ?
                                                            ShowSensor(sens, index)
                                                            : false
                                                        :
                                                        ShowSensor(sens, index)
                                    ))
                                    : false
                            }
                        </>
                            : <div className={'flex items-center justify-center space-x-2'}>
                                <div class="text-indigo-100 text-6xl uppercase">{t('error.noData')}</div>
                                {history.push('/welcome')}
                            </div>
                    }
                </div>
            </div>
            <div className={`fixed md:right-0 md:h-full md:w-1/3 sm:w-full sm:h-auto sm:bottom-0 bg-text-white p-4 shadow flex-col ${showMobileFilters ? 'hidden sm:flex md:flex' : 'hidden'}`}>
                <div className={`absolute right-0 mr-4 cursor-pointer`} onClick={() => setShowMobileFilters(false)}>
                    <img src={CloseIcon} alt={'Close button'} className={`w-4`} />
                </div>
                <Header6 textAlign={'left'} color={'indigo-100'}>Filter</Header6>
                <div style={{ height: 20 }} />
                <Dropdown
                    smMaxWidth={'100%'}
                    placeholderText={'Location'}
                    options={availableLocations}
                    onValueChange={value => changeLocation(value)}
                />
                <div style={{ height: 15 }} />
                <Dropdown
                    smMaxWidth={'100%'}
                    placeholderText={'Group'}
                    options={availableGroups}
                    onValueChange={value => changeGroup(value)}
                />
                <div style={{ height: 15 }} />
                <Dropdown
                    smMaxWidth={'100%'}
                    placeholderText={'Sensor'}
                    options={availableSensors}
                    onValueChange={value => changeSensor(value)}
                />
                <div style={{ height: 15 }} />
                <MonthPicker
                    placeholder={'Month'}
                    onValueChange={(selection) => changeMonth(selection)}
                />

                <div className={`hidden sm:flex mt-4`}>
                    <PrimaryButton
                        text={'Done'}
                        submit={() => setShowMobileFilters(false)}
                    />
                </div>
            </div>
        </>
    )
}

export default withRouter(HaccpPage);
