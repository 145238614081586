import React from 'react';

import { useTranslation } from 'react-i18next';

const NotFoundPage = () => {
    // Translation
    const { t } = useTranslation();

    return (
        <div className={'flex w-screen h-screen sm:flex-col-reverse sm:h-auto items-center justify-center space-x-2'}>
            <div class="text-indigo-100 text-6xl"> 404|</div>
            <div class="uppercase">{t('error.notFound')}</div>
        </div>
    )
}

export default NotFoundPage;