import React from 'react';

import Header1 from '../elements/Header1';
import Header5 from '../elements/Header5';

// Translation
import { useTranslation } from 'react-i18next';
import LanguageSelect from '../components/inputs/LanguageSelect';

const ResetSuccessPage = () => {

    //Translation
    const { t } = useTranslation();

    return (
        <div className={'flex w-screen h-screen sm:flex-col-reverse sm:h-auto'}>
            <div className={'bg-white px-24 md:px-6 h-screen w-2/5 md:w-3/6 flex items-center justify-center sm:w-full relative'}>
                <div>
                    <LanguageSelect />
                    <h1 className={'text-indigo-100'} style={{ fontSize: 72, fontWeight: 'bold', textAlign: 'center' }}>kelvyn</h1>
                    <Header1 color={'text-indigo-100'}>{t('portal')}</Header1>

                    <div className={'flex justify-center'}>
                        <img className={'h-16 w-16 mt-24'} src={require('../assets/icons/email-fill.svg')} alt={'Email illiustration'} />
                    </div>

                    <Header5 textAlign={'text-left'} color={'indigo-100'} paddingTop={60} paddingBottom={20} additionalClass={'text-center'} style={{ fontSize: 15, width: 432 }}>{t('login.resetSuccess.checkEmail')}</Header5>

                    <Header5 textAlign={'text-left'} color={'login-secondary'} paddingTop={10} additionalClass={'text-center'} >{t('login.resetSuccess.followLink')}</Header5>


                </div>
            </div>

            <div className={'h-screen w-3/5 md:w-3/6 sm:h-16 sm:w-full'}>
                <img src={require('../assets/images/iceberg.jpg')} alt={'Iceberg background'} className={'h-screen w-full object-cover sm:h-16 sm:object-none'} />
            </div>
        </div>
    )
}

export default ResetSuccessPage;