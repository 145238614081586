import API from '../utils/APIDomain';

export const delMeasurementReportList = (id) => {
    return new Promise(async (resolve, reject) => {
        API.delete('haccp/rules/' + id)
            .then(res => {
                resolve(res);
            })
            .catch(e => {
                reject(e.response);
            })
    })
}

export const getMeasurementReportList = () => {
    return new Promise(async (resolve, reject) => {
        API.get('haccp/measurements/reporting/list')
            .then(res => {
                resolve(res);
            })
            .catch(e => {
                reject(e.response);
            })
    })
}

export const getMeasurementReportCSV = () => {
    return new Promise(async (resolve, reject) => {
        API.get('haccp/measurements/reporting/csv')
            .then(res => {
                resolve(res);
            })
            .catch(e => {
                reject(e.response);
            })
    })
}

export const getMeasurementDetails = (id) => {
    return new Promise(async (resolve, reject) => {
        API.get('haccp/measurements/' + id)
            .then(res => {
                resolve(res);
            })
            .catch(e => {
                reject(e.response);
            })
    })
}

export const getRuleList = () => {
    return new Promise(async (resolve, reject) => {
        API.get('haccp/rules')
            .then(res => {
                resolve(res);
            })
            .catch(e => {
                reject(e.response);
            })
    })
}

export const getRuleDetails = (id) => {
    return new Promise(async (resolve, reject) => {
        API.get('haccp/rules/' + id)
            .then(res => {
                resolve(res);
            })
            .catch(e => {
                reject(e.response);
            })
    })
}

export const getRuleBySensorId = (sensorId) => {
    return new Promise(async (resolve, reject) => {
        API.get('haccp/rules')
            .then(res => {
                if (res && res.data && res.data.results && res.data.results.length > 0) {
                    res.data.results.map((rule) => {
                        return rule.sensor_id === sensorId ? resolve(rule) : ''
                    })
                    resolve('');
                }
                else {
                    resolve('');
                }
            })
            .catch(e => {
                reject(e.response);
            })
    })
}

export const getMeasurementList = () => {
    return new Promise(async (resolve, reject) => {
        API.get('haccp/measurements')
            .then(res => {
                resolve(res);
            })
            .catch(e => {
                reject(e.response);
            })
    })
}

export const getMeasurementListBySensorId = (sensorId) => {
    return new Promise(async (resolve, reject) => {
        API.get(`haccp/measurements?sensor_id=${sensorId}`)
            .then(res => {
                resolve(res);
            })
            .catch(e => {
                reject(e.response);
            })
    })
}

export const getMeasurementListByMonth = (sensorId, month, year) => {
    return new Promise(async (resolve, reject) => {
        API.get(`haccp/measurements?sensor_id=${sensorId}&time_after=${parseInt(year)}-${parseInt(month)}-01&time_before=${parseInt(month) === 12 ? parseInt(year) + 1 : parseInt(year)}-${parseInt(month) === 12 ? '01' : parseInt(month) + 1}-01`)
            .then(res => {
                if (res.data && res.data.length > 0) {
                    resolve(res.data);
                }
                else resolve([]);
            })
            .catch(e => {
                reject(e.response);
            })
    })
}

export const getLastMeasurement = (sensorId, count) => {
    return new Promise(async (resolve, reject) => {
        API.get(`haccp/measurements?sensor_id=${sensorId}&page=${parseInt(count / 50) + 1}`)
            .then(res => {
                resolve(res);
            })
            .catch(e => {
                reject(e.response);
            })
    })
}

export const updateMeasurementComment = (sensor_id, rule, time, temperature, alarm_type, comment, company_id, id) => {
    const body = {
        "sensor_id": sensor_id,
        "rule": rule,
        "time": time,
        "temperature": temperature,
        "alarm_type": alarm_type,
        "comment": comment,
        "company_id": company_id,
        "id": id
    }

    return new Promise(async (resolve, reject) => {
        API.put('/haccp/measurements/' + id + '/comment', body)
            .then(res => {
                resolve(res);
            })
            .catch(e => {
                reject(e.response);
            })
    })
}

export const updateMeasurementCommentOnly = (comment, id) => {
    const body = {
        "comment": comment,
        "id": id
    }

    return new Promise(async (resolve, reject) => {
        API.put('/haccp/measurements/' + id + '/comment', body)
            .then(res => {
                resolve(res);
            })
            .catch(e => {
                reject(e.response);
            })
    })
}

export const postMeasurementCreate = (sensor_id, rule, time, temperature, alarm_type, comment) => {
    const body = {
        "sensor_id": sensor_id,
        "rule": rule,
        "time": time,
        "temperature": temperature,
        "alarm_type": alarm_type,
        "comment": comment
    }

    return new Promise(async (resolve, reject) => {
        API.post('/haccp/measurements', body)
            .then(res => {
                resolve(res);
            })
            .catch(e => {
                reject(e.response);
            })
    })
}

export const postRuleCreate = (sensor_id, status, threshold, sample_hours) => {
    const body = {
        "sensor_id": sensor_id,
        "status": status,
        "threshold": threshold,
        "sample_hours": sample_hours
    }

    return new Promise(async (resolve, reject) => {
        API.post('/haccp/rules', body)
            .then(res => {
                resolve(res);
            })
            .catch(e => {
                reject(e.response);
            })
    })
}


export const updateRuleComment = (sensor_id, status, threshold, sample_hours, id) => {
    const body = {
        "sensor_id": sensor_id,
        "status": status,
        "threshold": threshold,
        "sample_hours": sample_hours
    }

    return new Promise(async (resolve, reject) => {
        API.put('/haccp/rules/' + id, body)
            .then(res => {
                resolve(res);
            })
            .catch(e => {
                reject(e.response);
            })
    })
}