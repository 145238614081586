import React, { useState, useEffect } from 'react';
import HeatMapRow from './HeatMapRow';

// import Rodal from 'rodal';

// include styles
import 'rodal/lib/rodal.css';

const HeatMapTable = ({ measurements, sensor, threshold }) => {
    const [measurementsState, setMeasure] = useState(null)
    // get measures
    useEffect(() => {
        setMeasure(measurements)
    }, [setMeasure, measurements, measurementsState])
    if (measurementsState !== null) {
        return (
            <div>
                <table className={'border-0 w-full'}>
                    <tbody>
                        {measurementsState.map((row, index) => (
                            <React.Fragment key={`heat_map_row_${index}`}>
                                <HeatMapRow elements={row} lastRow={measurements.length === index + 1} sensor={sensor} threshold={threshold} />
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>


        )
    } else { return null }
}


export default HeatMapTable;
