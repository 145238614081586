import React, { useState } from 'react';
//Elements
import Header5 from '../../elements/Header5';
import Header6 from '../../elements/Header6';
import TextInput from '../../components/inputs/TextInput';
import PrimaryButton from '../../components/buttons/PrimaryButton';
//Assets
import CloseButtonIcon from '../../assets/icons/close-tooltip.svg';
//Services
import { createSensor } from '../../services/sensor';
// Translation
import { useTranslation } from 'react-i18next';
import Dropdown from '../inputs/Dropdown';
import { useDispatch } from 'react-redux';
import { setCreatedSensor } from '../../redux/actions/sensor';

const AddNewCreateEdit = ({ show, groups, onClose }) => {
  // state
  const [sensorName, setSensorName] = useState('');
  const [sensorID, setSensorID] = useState('');
  const [location, setLocation] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [newGroupName, setNewGroupName] = useState('');
  const [addGroup, setAddGroup] = useState(false);
  const [error, setError] = useState('');
  //Translation
  const { t } = useTranslation();
  //Redux
  const dispatch = useDispatch();

  //Functions
  const closeModal = () => {
    setSensorName('');
    setSensorID('');
    setLocation('');
    setSelectedGroup('');
    setNewGroupName('');
    setAddGroup('');
    setError('');
    onClose();
  };

  const addNewGroup = () => {
    setAddGroup(!addGroup);
  };

  const handleSaveLocationGroups = () => {
    const req_payload = {
      name: sensorName,
      location: location,
      location_group: selectedGroup.value || newGroupName,
      is_active: true,
      sensor_id: sensorID,
    };
    createSensor(req_payload)
      .then(() => {
        closeModal();
        dispatch(setCreatedSensor(true));
      })
      .catch((e) => setError(e.data));
  };

  return (
    <div
      className={`fixed items-center z-20 h-full top-0 right-0 left-256px md:left-0 bg-text-onyx-25 ${
        show ? 'flex' : 'hidden'
      } flex-row justify-center`}
    >
      <div
        className={`w-5/12 z-30 md:w-full bg-text-white rounded p-8 overflow-y-scroll`}
        style={{ height: '90%' }}
      >
        <img
          src={CloseButtonIcon}
          alt={'Close modal button'}
          className={`w-6 h-6 float-right cursor-pointer`}
          onClick={() => closeModal()}
        />

        <div className={'mt-8 flex items-center justify-between'}>
          <div className={`w-11/12`}>
            <Header5 textAlign={'left'} color={'indigo-100'}>
              {t('dashboard.addNew')}
            </Header5>
          </div>
        </div>

        {error
          ? Object.values(error).length > 0
            ? Object.entries(error).map((value) => {
                let translationsArray = [];
                value.length > 0 &&
                  value[1].map((value) => {
                    if (value === 'sensor with this Sensor Id already exists.')
                      translationsArray.push('dashboard.error.sensorID');
                    return value;
                  });
                return translationsArray.map((translation) => {
                  return (
                    <div className={'flex flex-row items-center mt-3'}>
                      <svg
                        className={'h-5 text-orange-100'}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <Header6 textAlign={'left'} color={'orange-100 ml-1'}>
                        {t(translation)}
                      </Header6>
                    </div>
                  );
                });
              })
            : false
          : false}

        <div className={'mt-8'}>
          <Header6 textAlign={'left'} color={'indigo-100'}>
            {t('dashboard.sensorName')}
          </Header6>
          <TextInput
            value={sensorName}
            placeholder={t('dashboard.sensorName')}
            inputChange={(text) => setSensorName(text)}
          />
        </div>

        <div className={'mt-8'}>
          <Header6 textAlign={'left'} color={'indigo-100'}>
            {t('dashboard.sensorID')}
          </Header6>
          <TextInput
            value={sensorID}
            placeholder={t('dashboard.sensorPlaceholder')}
            inputChange={(text) => setSensorID(text)}
          />
        </div>

        <div className={'mt-8'}>
          <Header6 textAlign={'left'} color={'indigo-100'}>
            {t('dashboard.location')}
          </Header6>
          <TextInput
            value={location}
            placeholder={t('dashboard.locationName')}
            inputChange={(text) => setLocation(text)}
          />
        </div>

        <div className={'mt-8'}>
          <Header6 textAlign={'left'} color={'indigo-100 pb-5'}>
            {t('dashboard.groups')}
          </Header6>
          <Dropdown
            placeholderText={t('haccp.haccp.group')}
            options={groups}
            inputValue={selectedGroup}
            resetDropdown={addGroup === true}
            onValueChange={(value) => setSelectedGroup(value)}
          />
          {addGroup ? (
            <div className={'flex flex-row justify-between items-center'}>
              <TextInput
                value={newGroupName}
                placeholder={t('dashboard.group')}
                inputChange={(value) => setNewGroupName(value)}
              />
              <img
                src={CloseButtonIcon}
                alt={'Close modal button'}
                className={`w-6 h-6 float-right cursor-pointer`}
                onClick={() => addNewGroup()}
              />
            </div>
          ) : (
            <div
              className={`text-indigo-100 underline pt-5 cursor-pointer`}
              onClick={() => addNewGroup()}
            >
              + {t('dashboard.addNewGroup')}
            </div>
          )}
        </div>

        <div className={'mt-8 ml-25% w-1/2'}>
          <PrimaryButton
            text={t('dashboard.save')}
            submit={() => handleSaveLocationGroups()}
            disabled={
              !(
                sensorName &&
                sensorID &&
                location &&
                (selectedGroup || newGroupName)
              )
            }
            icon={false}
          />
        </div>
      </div>
    </div>
  );
};

export default AddNewCreateEdit;
