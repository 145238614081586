import API from '../utils/APIDomain';

export const updateSensor = (sensorId, data) => {
    return new Promise(async (resolve, reject) => {
        API.put('device/sensors/' + sensorId, data)
            .then(res => {
                resolve(res);
            })
            .catch(e => {
                reject(e.response);
            })
    })
}

export const getAllSensors = () => {
    return new Promise(async (resolve, reject) => {
        API.get('device/sensors')
        .then(res=> {
            resolve(res);
        })
        .catch(e => {
            reject(e.response)
        })
    })
}

export const createSensor = (data) => {
    return new Promise(async (resolve, reject) => {
        API.post('device/sensors/' , data)
          .then(res => {
              resolve(res);
          })
          .catch(e => {
              reject(e.response);
          })
    })
}