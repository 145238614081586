import React from 'react';

//Components
import Header4 from '../elements/Header4';
import Header1 from '../elements/Header1';

//Translation
import { useTranslation } from 'react-i18next';

const WelcomePage = () => {
    //Translation
    const { t } = useTranslation();

    return (
        <div className={'flex flex-col px-16 py-12 min-h-screen w-screen space-y-6'}>
            <Header1 textAlign={'left'} color={'text-text-onyx-50'}>{t('welcome.dearCustomer')}</Header1>
            <div className={'space-y-1'}>
                <Header4 textAlign={'left'} color={'text-onyx-50'}>{t('welcome.noSensors')}</Header4>
                <Header4 textAlign={'left'} color={'text-onyx-50'}>{t('welcome.untilThen')}</Header4>
                <Header4 textAlign={'left'} color={'text-onyx-50'}>{t('welcome.anyQuestions')}</Header4>
            </div>
            <Header4 textAlign={'left'} color={'text-onyx-50'}>{t('welcome.greetings')}</Header4>
        </div>
    )
}

export default WelcomePage;
