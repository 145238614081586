import React, { useState, useEffect, useContext } from 'react';

//Elements
import MenuItem from '../../elements/menu/MenuItem';
import Header6 from '../../elements/Header6';

//Constants
import { menuItems } from '../../constants/menuItems';

//Modules
import { withRouter, useLocation } from 'react-router-dom';

//Services
import { logoutUser, getProfile } from '../../services/auth';

// Translation
import { useTranslation } from 'react-i18next';

import { AuthContext } from '../../context/useAuth';

const NavigationMenu = ({ onToggleExpand, history }) => {
  //Router
  const location = useLocation();
  const { setAuthToken } = useContext(AuthContext);

  //State
  const [expanded, setExpanded] = useState(true);
  const [selected, setSelected] = useState(null);
  const [userName, setUserName] = useState('');

  //Functions
  const selectItem = (index) => {
    history.push(menuItems[index].slug);
  };

  //Translation
  const { t } = useTranslation();

  const toggleExpand = () => {
    setExpanded(!expanded);
    onToggleExpand(!expanded);
  };

  const handleLogout = () => {
    setAuthToken('');
    logoutUser();
    history.go(0);
  };

  //Effect
  useEffect(() => {
    setSelected(location.pathname);
  }, [location]);

  useEffect(() => {
    let subscribed = true;
    getProfile()
      .then((res) => {
        if (subscribed) {
          //Only update the state while the component is still mounted
          setUserName(
            res && res.profile
              ? res.profile.name + ' ' + res.profile.family_name
              : ''
          );
        }
      })
      .catch((e) => console.log(e));
    //Set subscribed to false when the component unmounts, to avoid attempting further state changes
    return () => (subscribed = false);
  }, []);

  return (
    <>
      <div
        className={`fixed sm:relative h-screen w-${
          expanded ? '64' : '20'
        } md:w-${expanded ? '56' : '20'} sm:${
          expanded ? 'w-full' : 'hidden'
        } shadow-lg pt-8 sm:pt-1 pb-6 flex flex-col justify-between duration-100`}
      >
        <div>
          <h1 className={'text-indigo-100 m-0 text-4xl font-bold text-center'}>
            {expanded ? 'kelvyn' : 'k'}
          </h1>
          <div className={'absolute right-0 top-0 hidden sm:block'}>
            <img
              src={require('../../assets/icons/close-tooltip.svg')}
              alt={'close menu'}
              className={`w-6 h-6 m-5 cursor-pointer`}
              onClick={() => toggleExpand()}
            />
          </div>

          <div className={'pt-12'}>
            {menuItems.length > 0
              ? menuItems.map((item, index) => (
                  <div
                    key={`menu_item_${index}`}
                    onClick={() => selectItem(index)}
                  >
                    <MenuItem
                      icon={item.icon}
                      title={t(item.title)}
                      selected={selected === item.slug}
                      expanded={expanded}
                      onClick={() => console.log(index)}
                    >
                      {item.children}
                    </MenuItem>
                  </div>
                ))
              : false}
          </div>
        </div>
        <div className={'px-6 relative'}>
          {expanded ? (
            <>
              <Header6 textAlign={'left'}>{userName}</Header6>
              <div onClick={() => handleLogout()}>
                <Header6
                  color={'indigo-100 underline cursor-pointer pt-px'}
                  textAlign={'left'}
                >
                  {t('menu.logout')}
                </Header6>
              </div>
            </>
          ) : (
            false
          )}
          <div
            className={`absolute right-neg20px ${
              expanded ? 'top-0' : 'top-neg40px'
            } sm:hidden h-10 w-10 rounded-full bg-indigo-100 cursor-pointer flex justify-center items-center`}
            onClick={() => toggleExpand()}
          >
            {expanded ? (
              <img
                className={'w-5'}
                src={require('../../assets/icons/arrow-left-white.png')}
                alt={'toggle expand'}
              />
            ) : (
              <img
                className={'w-5'}
                src={require('../../assets/icons/arrow-right-white.png')}
                alt={'toggle expand'}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className={`absolute mt-4 ml-4 cursor-pointer hidden sm:block ${
          expanded ? 'sm:hidden' : false
        }`}
        onClick={() => toggleExpand()}
      >
        <img
          src={require('../../assets/icons/hamburger-menu.svg')}
          alt={'hamburger menu'}
          className={`w-4 h-4`}
        />
      </div>
    </>
  );
};

export default withRouter(NavigationMenu);
