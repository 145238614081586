import React from 'react';

const Header4 = (props) => {
    const paddingTopValue = props.paddingTop ? props.paddingTop : 0
    const paddingBottomValue = props.paddingBottom ? props.paddingBottom : 0
    return (
        <h6 className={`text-${props.textAlign ? props.textAlign : 'center'} text-${props.color ? props.color : 'text-onyx'} ${props.additionalClass ? props.additionalClass : ''} font-sans text-lg`}
            style={{ paddingTop: paddingTopValue, paddingBottom: paddingBottomValue }}>
            {props.children}
        </h6>
    )
}

export default Header4;
