import React, { useEffect, useState } from 'react';

//Components
import TextInput from '../../components/inputs/TextInput';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import RangeSlider from '../../components/selectionControls/RangeSlider';
import Toggle from '../../components/inputs/Toggle';

//Elements
import Header4 from '../../elements/Header4';
import Header6 from '../../elements/Header6';

//Assets
import CloseButtonIcon from '../../assets/icons/close-tooltip.svg';

//Modules
import moment from 'moment';

//Services
import { updateSensor } from '../../services/sensor';
import { updateRuleComment } from '../../services/haccp';

// Translation
import { useTranslation } from 'react-i18next';

//Redux
import { useDispatch } from 'react-redux';
import { setEditedSensor } from '../../redux/actions/haccp';

const HaccpEditSettings = ({ show, sensor, onClose, onSave, rule, lastMeasurement }) => {
    //Translation
    const { t } = useTranslation();

    //Redux
    const dispatch = useDispatch();

    //State
    const [name, setName] = useState('');
    const [measurementsPerDay, setMeasurementsPerDay] = useState('');
    const [measurementTimes, setMeasurementTimes] = useState([]);
    const [threshold, setThreshold] = useState({
        low: -20,
        high: 65
    })
    const [enableLowThreshold, setEnableLowThreshold] = useState(true);
    const [enableHighThreshold, setEnableHighTreshold] = useState(true);

    useEffect(() => {
        if (sensor && name !== sensor.label && name === '') {
            setName(sensor.label);
        }
        if (rule && measurementsPerDay !== rule.sample_hours.length && measurementTimes !== rule.sample_hours && threshold !== { low: rule.threshold[0], high: rule.threshold[1] } && measurementsPerDay === '') {
            setMeasurementsPerDay(rule.sample_hours.length);
            setMeasurementTimes(rule.sample_hours);
            setThreshold({ low: rule.threshold[0], high: rule.threshold[1] });
        }
        // eslint-disable-next-line
    }, [sensor, rule]);

    //Functions
    const closeModal = () => {
        setName('');
        setMeasurementsPerDay('');

        onClose();
    }

    async function setMeasurementCount(value) {
        if (value) {
            const count = parseInt(value);
            let times = {};

            for (let i = 0; i < count; i++) {
                times = { ...times, [(i).toString()]: '' };
            }

            setMeasurementsPerDay(count);
            setMeasurementTimes(times);
        }
        else {
            setMeasurementsPerDay('');
        }

    }

    const setThresholdRange = (range) => {
        setThreshold({
            low: range[0],
            high: range[1]
        })
    }

    const handleSaveSensorSettings = () => {
        dispatch(setEditedSensor(name, sensor.sensorId, [threshold.low, threshold.high], Object.values(measurementTimes), sensor.rule.id));
        updateRuleComment(sensor.sensorId, true, [threshold.low, threshold.high], Object.values(measurementTimes), sensor.rule.id)
            .then(() => {
                if (name !== sensor.label) {
                    updateSensor(sensor.id, {
                        "name": name,
                        "is_active": true,
                        "sensor_id": sensor.sensorId
                    })
                        .then(() => {

                            closeModal();
                        })
                        .catch(e => {
                            console.log(e);
                        })
                }
                else closeModal();
            })
            .catch(e => {
                console.log(e);
            })
    }

    return (
        <div
            className={`fixed items-center z-20 h-full top-0 right-0 left-256px md:left-0 bg-text-onyx-25 ${show ? 'flex' : 'hidden'} flex-row justify-center`}
        >
            <div className={`w-5/12 z-30 md:w-full bg-text-white rounded p-8 overflow-y-scroll`} style={{ height: '80%' }}>
                <img
                    src={CloseButtonIcon}
                    alt={'Close modal button'}
                    className={`w-6 h-6 float-right cursor-pointer`}
                    onClick={() => closeModal()}
                />
                <Header4 textAlign={'left'} color={'indigo-100'}>{t('haccp.settings.haccpSettings')}</Header4>

                <div className={'mt-8'}>
                    <Header6 textAlign={'left'} color={'indigo-100'}>{t('haccp.settings.name')}</Header6>
                    <TextInput
                        value={name}
                        placeholder={t('haccp.settings.name')}
                        inputChange={(text) => setName(text)}
                    />
                </div>

                <div className={'mt-3'}>
                    <Header6 textAlign={'left'} color={'indigo-100'}>{t('haccp.settings.measurePerDay')} (1-24)</Header6>
                    <TextInput
                        value={measurementsPerDay}
                        placeholder={t('haccp.settings.measurePerDay')}
                        inputChange={(text) => parseInt(text) > 24
                            ? setMeasurementCount('24')
                            : parseInt(text) < 0
                                ? setMeasurementCount('0')
                                : setMeasurementCount(text)
                        }
                    />
                </div>

                <div className={'mt-3'}>
                    <Header6 textAlign={'left'} color={'indigo-100'}>{t('haccp.settings.measureTimes')} (eg: 14:00)</Header6>
                    {measurementTimes ?
                        Object.keys(measurementTimes).length > 0 ?
                            Object.keys(measurementTimes).map((time, index) => (
                                <TextInput
                                    key={`measurement_time_${index}`}
                                    value={measurementTimes[index]}
                                    placeholder={`${t('haccp.settings.measurement')} ${parseInt(time) + 1}*`}
                                    inputChange={(text) => setMeasurementTimes({ ...measurementTimes, [time]: text })}
                                />
                            ))
                            : false
                        : false
                    }
                </div>

                <div className={'mt-8 ml-25% w-1/2'}>
                    <PrimaryButton
                        text={t('haccp.settings.saveChanges')}
                        submit={() => handleSaveSensorSettings()}
                    />
                </div>

                <div className={'mt-8'}>
                    <Header6 textAlign={'left'} color={'indigo-100'}>{t('haccp.settings.thresholds')}</Header6>
                    <RangeSlider
                        disabled={!enableLowThreshold && !enableHighThreshold}
                        defaultValues={[parseInt(threshold.low), parseInt(threshold.high)]}
                        onValueChange={(range) => setThresholdRange(range)}
                    />

                    {/* Low Threshold */}
                    <div className={'w-full flex'}>
                        <div className={'flex justify-start items-center w-1/6'}>
                            <Toggle
                                enabled={enableLowThreshold}
                                onToggle={() => setEnableLowThreshold(!enableLowThreshold)}
                            />
                        </div>
                        <div className={'w-5/6'}>
                            <TextInput
                                //disabled={!enableLowThreshold}
                                disabled
                                value={threshold.low}
                                placeholder={t('haccp.settings.lowThreshold')}
                                inputChange={text => setThreshold({ ...threshold, low: text })}
                            />
                        </div>
                    </div>

                    {/* High Threshold */}
                    <div className={'w-full flex'}>
                        <div className={'flex justify-start items-center w-1/6'}>
                            <Toggle
                                enabled={enableHighThreshold}
                                onToggle={() => setEnableHighTreshold(!enableHighThreshold)}
                            />
                        </div>
                        <div className={'w-5/6'}>
                            <TextInput
                                //disabled={!enableHighThreshold}
                                disabled
                                value={threshold.high}
                                placeholder={t('haccp.settings.highThreshold')}
                                inputChange={text => setThreshold({ ...threshold, high: text })}
                            />
                        </div>
                    </div>

                </div>

                <div className={'mt-8 ml-25% w-1/2'}>
                    <PrimaryButton
                        text={t('haccp.settings.saveChanges')}
                        submit={() => handleSaveSensorSettings()}
                    />
                </div>

                <div className={'mt-8'}>
                    <Header4 textAlign={'left'} color={'indigo-100'}>{t('haccp.settings.techDetails')}</Header4>
                    <div className={`flex mt-8 justify-between`}>
                        <div className={'w-2/3'}>
                            <Header6 textAlign={'left'} color={'indigo-100'}>ID</Header6>
                            <Header6 textAlign={'left'} color={'text-onyx-87'}>{rule ? rule.sensor_id : '/'}</Header6>
                        </div>

                        <div className={'w-1/3'}>
                            <Header6 textAlign={'left'} color={'indigo-100'}>{t('haccp.settings.battery')}</Header6>
                            <Header6 textAlign={'left'} color={'text-onyx-87'}>3.4 V</Header6>
                        </div>
                    </div>

                    <div className={'mt-3'}>
                        <Header6 textAlign={'left'} color={'indigo-100'}>{t('haccp.settings.lastData')}</Header6>
                        <Header6 textAlign={'left'} color={'text-onyx-87'}>{lastMeasurement ? moment(lastMeasurement.time).format('DD MMM YYYY hh:mm:ss') : '/'}</Header6>
                    </div>
                </div>

                <div className={'h-8'} />
            </div>
        </div >
    )
}

export default HaccpEditSettings;