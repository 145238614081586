const initialState = {
    editedSensorName: null,
    editedSensorId: null,
    editedSensorThreshold: null,
    editedSensorMeasurementTimes: null,
    editedSensorRuleId: null,
    commentModalOpened: false,
    commentModalId: null,
    editedCommentId: null,
    editedComment: null,
}

const haccpReducer = (state = initialState, action) => {
    if (action.type === 'SET_EDITED_SENSOR') {
        return Object.assign({}, state, {
            editedSensorName: action.sensorName,
            editedSensorId: action.sensorId,
            editedSensorThreshold: action.sensorThreshold,
            editedSensorMeasurementTimes: action.sensorMeasurementTimes,
            editedSensorRuleId: action.sensorRuleId,
        })
    }
    else if (action.type === 'OPEN_COMMENT_MODAL') {
        return Object.assign({}, state, {
            commentModalOpened: true,
            commentModalId: action.id
        })
    }
    else if (action.type === 'CLOSE_COMMENT_MODAL') {
        return Object.assign({}, state, {
            commentModalOpened: false,
            commentModalId: null
        })
    }
    else if (action.type === 'SET_EDITED_COMMENT') {
        return Object.assign({}, state, {
            editedCommentId: action.id,
            editedComment: action.text
        })
    }
    else return state;
}

export default haccpReducer;