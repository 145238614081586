import React from 'react';

//Components
import Dropdown from '../components/inputs/Dropdown';
import DatePicker from '../components/selectionControls/DatePicker';

//Elements
import Header2 from '../elements/Header2';

// Translation
import { useTranslation } from 'react-i18next';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { setAlarm } from '../redux/actions/alarm';

const AlarmLogPage = () => {
    //Redux
    const dispatch = useDispatch();
    const alarmTitle = useSelector(state => state.alarm.alarm);

    //Translation
    const { t } = useTranslation();

    return (
        <div className={`py-10 px-16 sm:py-4 sm:px-4 w-full`} style={{ backgroundColor: '#fdfdfe' }}>
            <div className={`flex md:justify-between`}>
                <Header2>{alarmTitle}</Header2>
                <div className={`w-3/4 grid grid-cols-4 gap-3 ml-8 md:hidden sm:hidden`}>
                    <Dropdown
                        placeholderText={t('haccp.haccp.location')}
                        options={[{ label: 'test1', value: 'test1' }, { label: 'test2', value: 'test2' }]}
                        onValueChange={value => dispatch(setAlarm(value.value))}
                    />
                    <Dropdown
                        placeholderText={t('haccp.haccp.group')}
                        options={['Test1', 'Test2']}
                        onValueChange={value => console.log(value)}
                    />
                    <Dropdown
                        placeholderText={t('haccp.haccp.sensor')}
                        options={[{ label: 'All Sensors', value: 'All Sensors' }]}
                        onValueChange={value => console.log(value)}
                    />
                    <DatePicker
                        placeholder={'Date range'}
                        anchor={'right'}
                        onValueChange={(selection) => console.log(selection)}
                    />
                </div>
            </div>

        </div>
    )
}

export default AlarmLogPage;