export const setEditedSensor = (sensorName, sensorId, sensorThreshold, sensorMeasurementTimes, sensorRuleId) => {
    return {
        type: 'SET_EDITED_SENSOR',
        sensorName: sensorName,
        sensorId: sensorId,
        sensorThreshold: sensorThreshold,
        sensorMeasurementTimes: sensorMeasurementTimes,
        sensorRuleId: sensorRuleId
    }
}

export const openCommentModal = (id) => {
    return {
        type: 'OPEN_COMMENT_MODAL',
        id: id
    }
}

export const closeCommentModal = () => {
    return {
        type: 'CLOSE_COMMENT_MODAL',
    }
}

export const setEditedComment = (id, text) => {
    return {
        type: 'SET_EDITED_COMMENT',
        id: id,
        text: text
    }
}
