import React, { useState } from 'react';

//Elements
import Header6 from '../../elements/Header6';

//Modules
import Flag from 'react-world-flags'

// Translation
import { useTranslation } from 'react-i18next';

//Assets
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrow-right.svg';

const LanguageSelect = () => {
    //Constants
    const options = [
        {
            label: 'DE',
            value: 'de',
            flag: 'de'
        },
        {
            label: 'EN',
            value: 'en',
            flag: 'gb'
        }
    ];

    //Translation
    const { t, i18n } = useTranslation();
    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };

    //State
    const [menuOpen, setMenuOpen] = useState(false);

    //Functions
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    }

    const selectLanguage = (index) => {
        setMenuOpen(false);
        changeLanguage(options[index].value);
        //onLanguageChange(options[index]);
    }

    return (
        <div className={`absolute top-0 right-0 mt-4 mr-8`}>
            <div className={`flex w-full justify-end`}>
                <div
                    className={`flex w-16 h-6 justify-between items-center cursor-pointer`}
                    onClick={() => toggleMenu(true)}
                >
                    <Flag code={t('i18nSelectedFlag')} className={'h-3 w-5'} />
                    <Header6>{t('i18nSelectedLanguage')}</Header6>
                    <ArrowDownIcon transform={'rotate(90)'} className={`w-2 h-2`} />
                </div>
            </div>
            <div
                className={`flex w-full justify-end mt-2 ${menuOpen ? 'flex' : 'hidden'}`}
            >
                <div className={`absolute w-24 rounded bg-surface-light-smoke`}>
                    {options.length > 0 ?
                        options.map((option, index) => (
                            <div key={`language_${index}`}>
                                <div
                                    className={`flex w-full h-8 hover:bg-indigo-6 rounded justify-evenly items-center cursor-pointer`}
                                    onClick={() => selectLanguage(index)}
                                >
                                    <Flag code={option.flag} className={'h-3 w-5'} />
                                    <Header6>{option.label}</Header6>
                                </div>
                                {index !== options.length - 1 ? <div className={'w-4/5 mx-auto h-px bg-text-onyx-19'} /> : false}
                            </div>
                        ))
                        : false
                    }
                </div>
            </div>
        </div>
    )
}

export default LanguageSelect;