import React, { useState } from 'react';
import moment from 'moment';

import SelectionLabel from '../../elements/DatePicker/SelectionLabel';
import DateTextInput from '../../elements/DatePicker/DateTextInput';

import Header5 from '../../elements/Header5';

import ArrowRightIcon from '../../assets/icons/arrow-right-thin.svg';
import ArrowLeftIcon from '../../assets/icons/arrow-left-thin.svg';

const MonthPicker = ({ placeholder, onValueChange, disableFuture }) => {
    //Constants
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const currentYear = new Date().getFullYear();
    const currentMonth = moment(new Date()).format('MMMM');

    //State
    const [showCalendar, setShowCalendar] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState(moment(new Date()).format('MMMM'));
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    //Functions
    const selectMonth = (selection) => {
        setSelectedMonth(selection);
        setShowCalendar(false);
        onValueChange(`${selection} ${selectedYear}`);
    }

    const checkFuture = (selection) => {
        if (disableFuture) {
            if (selectedYear === currentYear) {
                if (months.indexOf(selection) <= months.indexOf(currentMonth)) {
                    selectMonth(selection);
                }
            }
            else {
                selectMonth(selection);
            }
        }
        else {
            selectMonth(selection);
        }
    }

    const yearPrev = (year) => {
        setSelectedYear(year);
        onValueChange(`${selectedMonth} ${year}`);
    }

    const yearNext = (year) => {
        if (disableFuture) {
            if (year <= currentYear) {
                setSelectedYear(year);
                onValueChange(`${selectedMonth} ${year}`);
            }
        }
        else {
            setSelectedYear(year);
            onValueChange(`${selectedMonth} ${year}`);
        }
    }

    return (
        <div className={`flex flex-col z-10`}>
            <div onClick={() => setShowCalendar(true)}>
                <DateTextInput
                    value={`${selectedMonth} ${selectedYear}`}
                    placeholder={placeholder}
                    inputChange={text => console.log(text)}
                />
            </div>

            <div className={`absolute w-64 mt-16 ${showCalendar ? 'initial bg-text-white' : 'hidden'} shadow`}>
                <div className={`w-full p-4 flex justify-between`}>
                    <img
                        onClick={() => yearPrev(selectedYear - 1)}
                        src={ArrowLeftIcon}
                        alt={'arrow left'}
                        className={`h-6 w-6 bg-indigo-6 p-2 rounded-full cursor-pointer`}
                    />
                    <Header5 color={'indigo-100'}>{selectedYear}</Header5>
                    <img
                        onClick={() => yearNext(selectedYear + 1)}
                        src={ArrowRightIcon}
                        alt={'arrow right'}
                        className={`h-6 w-6 bg-indigo-6 p-2 rounded-full cursor-pointer`}
                    />
                </div>
                {months.map((month, index) => (
                    <SelectionLabel
                        key={`month_picker_selection_label_${index}`}
                        text={month}
                        selected={selectedMonth}
                        future={disableFuture && (index > months.indexOf(currentMonth) && parseInt(selectedYear) >= parseInt(currentYear))}
                        setSelection={(item) => checkFuture(item)}
                    />
                ))}
            </div>
        </div>
    )
}

export default MonthPicker;

