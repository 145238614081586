import React from 'react';

import Header6 from '../Header6';
import Header5 from '../Header5';

const SelectionLabel = ({ text, selected, setSelection, future }) => {
    //Functions
    const handleSelect = () => {
        setSelection(text)
    }

    return (
        <div
            onClick={() => handleSelect()}
            className={`px-3 py-4 ${future ? 'bg-text-onyx-5' : false} ${selected === text ? false : future ? false : 'hover:bg-indigo-3'} ${future ? false : 'cursor-pointer'} ${selected === text ? 'bg-indigo-6' : false}`}
        >
            {selected === text ?
                <Header5 textAlign={'left'} color={`indigo-100`}>{text}</Header5>
                :
                <Header6 textAlign={'left'} color={`onyx opacity-87`}>{text}</Header6>
            }
        </div>
    )
}

export default SelectionLabel;