import React from 'react';

//React components

const DashboardPage = () => {

    return (
        <div style={{ display: 'flex', width: '100%', padding: '0 20px' }}>

        </div>
    )
}

export default DashboardPage;
