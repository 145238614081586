import React, { useState } from 'react'
import PrimaryButton from '../components/buttons/PrimaryButton'
import TextInput from '../components/inputs/TextInput'

//services
import { updateMeasurementCommentOnly } from '../services/haccp';

// Translation
import { useTranslation } from 'react-i18next';

const CommentCellPage = ({ id, value, onCommentChange }) => {

    //Translation
    const { t } = useTranslation();

    //states
    const [edit, setEdit] = useState(false);
    const [comment, setComment] = useState('');
    const [error, setError] = useState(null);

    const submitForm = () => {
        try {
            updateMeasurementCommentOnly(comment, id)
                .then(() => {
                    setEdit(!edit)
                    onCommentChange(comment, id);
                })
                .catch(e => {
                    console.error(e);
                    if (e) {
                        if (e.data) {
                            setError(e.data)
                        }
                    }
                    setEdit(!edit)
                })
        }
        catch (e) {
            console.log(e);
        }
    }

    const changeComment = (text) => {
        setComment(text);
    }

    return (
        <td className={`flex flex-1 text-left p-3 pl-0`}>
            {value !== undefined && value !== null && value !== '' ?
                value :
                edit === false ?
                    <button onClick={() => setEdit(!edit)}>
                        <img className={'h-4 object-right'} src={require('../assets/icons/pencil.svg')} alt={'Edit comment'} />
                    </button> :
                    <div>
                        <TextInput
                            placeholder={t('haccp.haccp.comment')}
                            inputChange={(text) => changeComment(text)}
                            type={'text'}
                            error={error ? error.error ? error.error.password ? error.error.password[0] : false : false : false}
                        />

                        <PrimaryButton
                            text={t('haccp.settings.saveChanges')}
                            submit={submitForm}
                            disabled={false}
                        />
                    </div>

            }
        </td>
    )
}

export default CommentCellPage