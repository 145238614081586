import React from 'react';

//Elements
import Header5 from '../../elements/Header5';
import Loader from '../../elements/Loader';

//Assets 
import SaveIcon from '../../assets/icons/save.svg'

const PrimaryButton = ({ text, loading, disabled, submit, icon }) => {
    return (
        <button
            disabled={disabled}
            className={`h-10 w-full my-3 md:w-full bg-indigo-100 flex rounded-full md:rounded items-center justify-center hover:shadow active:shadow ${disabled || loading ? 'cursor-not-allowed' : ''}`}
            onClick={() => submit()}
        >
            {loading ?
                <div className={'flex'}>
                    <div className={'h-5 w-5 border-2 border-t-0 rounded-full border-text-white border-opacity-38 animate-spin'} />
                    <Header5 color={'text-white'} opacity={38} className={'flex justify-screen h-screen items-center'}><Loader show /></Header5>
                </div>
                :
                <Header5 disabled={disabled} color={'text-white'}>{icon === true ? <img
                    src={SaveIcon}
                    alt={''}
                    className={`w-4 h-4  fill-current text-white`}
                /> : ''}{text}</Header5>
            }
        </button>
    )
}

export default PrimaryButton;
