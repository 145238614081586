import React, { useState, useEffect } from 'react';

//Components
import Header6 from '../../elements/Header6';
import Header4 from '../../elements/Header4';
import TextArea from '../../components/inputs/TextArea';
import PrimaryButton from '../../components/buttons/PrimaryButton';

//Assets
import CloseButtonIcon from '../../assets/icons/close-tooltip.svg';

//Modules
import moment from 'moment-timezone';

//Services
import { updateMeasurementComment, getMeasurementDetails } from '../../services/haccp';

// Translation
import { useTranslation } from 'react-i18next';

//Redux
import { useDispatch } from 'react-redux';
import { closeCommentModal, setEditedComment } from '../../redux/actions/haccp';


const HeatmapDetails = ({ show, onClose, id }) => {
    //Translation
    const { t } = useTranslation();

    //Redux
    const dispatch = useDispatch();

    //State
    const [error, setError] = useState(null);
    const [measurement, setMeasurement] = useState(null);
    const [edit, setEdit] = useState(false);

    //Effect
    useEffect(() => {
        if (id !== null && id !== undefined) {
            getMeasurementDetails(id).then(result => {
                setMeasurement(result.data)
                if (result.data.comment !== null) {
                    setEdit(true)
                }
            })
        }
    }, [setMeasurement, id])


    //Functions
    const closeModal = () => {
        dispatch(closeCommentModal());
        onClose();
    }

    const submitForm = () => {
        try {
            updateMeasurementComment(measurement.sensor, measurement.rule, measurement.time, measurement.temperature, measurement.alarm_type, measurement.comment, measurement.company_id, measurement.id)
                .then(result => {
                    console.log(result);
                    dispatch(setEditedComment(measurement.id, measurement.comment));
                    setEdit(!edit)
                })
                .catch(e => {
                    console.error(e);
                    if (e) {
                        if (e.data) {
                            setError(e.data)
                        }
                    }
                    setEdit(!edit)
                })
        }
        catch (e) {
            console.log(e);
        }
    }

    const changeComment = (text) => {
        const tmpMeasurement = measurement
        tmpMeasurement.comment = text
        setMeasurement(tmpMeasurement)
    }

    if (measurement !== null && show === true)
        return (
            <div
                className={`fixed items-center z-20 h-full top-0 right-0 left-256px md:left-0 bg-text-onyx-25 ${show ? 'flex' : 'hidden'} flex-row justify-center`}
            >
                <div className={`w-5/12 z-30 md:w-full bg-text-white rounded p-8 overflow-y-scroll`} style={{ height: '80%' }}>
                    <img
                        src={CloseButtonIcon}
                        alt={'Close modal button'}
                        className={`w-6 h-6 float-right cursor-pointer`}
                        onClick={() => closeModal()}
                    />

                    <Header4 color={'indigo-100'} textAlign={'left'} paddingBottom={20}>Food Display Case</Header4>

                    <Header6 color={'indigo-100'} textAlign={'left'} paddingTop={12} paddingBottom={12}>Basics</Header6>

                    <div className="flex mb-4">
                        <div className="w-1/2">
                            <Header6 textAlign={'left'} paddingTop={8} paddingBottom={8}>{t('haccp.haccp.location')}</Header6>
                        </div>
                        <div className="w-1/2">
                            <Header6 textAlign={'left'} paddingTop={8} paddingBottom={8}>Berlin</Header6>
                        </div>
                    </div>
                    <div className="flex mb-4">
                        <div className="w-1/2">
                            <Header6 textAlign={'left'} paddingTop={8} paddingBottom={8}>{t('haccp.haccp.group')}</Header6>
                        </div>
                        <div className="w-1/2">
                            <Header6 textAlign={'left'} paddingTop={8} paddingBottom={8}>Germany</Header6>
                        </div>
                    </div>
                    <div className="flex mb-4">
                        <div className="w-1/2">
                            <Header6 textAlign={'left'} paddingTop={8} paddingBottom={8}>{t('haccp.haccp.sensor')}</Header6>
                        </div>
                        <div className="w-1/2">
                            <Header6 textAlign={'left'} paddingTop={8} paddingBottom={8}>{measurement.sensor_id}</Header6>
                        </div>
                    </div>
                    <div className="flex mb-4">
                        <div className="w-1/2">
                            <Header6 textAlign={'left'} paddingTop={8} paddingBottom={8}>{t('haccp.haccp.alarmType')}</Header6>
                        </div>
                        <div className="w-1/2">
                            <Header6 textAlign={'left'} paddingTop={8} paddingBottom={8}>{measurement.alarm_type}</Header6>
                        </div>
                    </div>

                    <Header6 color={'indigo-100'} textAlign={'left'} paddingTop={12} paddingBottom={12}>{t('haccp.haccp.time')}</Header6>
                    <div className="flex mb-4">
                        <div className="w-1/2">
                            <Header6 textAlign={'left'} paddingTop={8} paddingBottom={8}>{t('haccp.haccp.date')}</Header6>
                        </div>
                        <div className="w-1/2">
                            <Header6 textAlign={'left'} paddingTop={8} paddingBottom={8}>{moment.tz(measurement.time, 'Etc/GMT+0').format('DD MMM YYYY')}</Header6>
                        </div>
                    </div>
                    <div className="flex mb-4">
                        <div className="w-1/2">
                            <Header6 textAlign={'left'} paddingTop={8} paddingBottom={8}>{t('haccp.haccp.time')}</Header6>
                        </div>
                        <div className="w-1/2">
                            <Header6 textAlign={'left'} paddingTop={8} paddingBottom={8}>{moment.tz(measurement.time, 'Etc/GMT+0').format('HH:mm')}</Header6>
                        </div>
                    </div>
                    <Header6 color={'indigo-100'} textAlign={'left'} paddingTop={12}>{t('haccp.haccp.comment')}</Header6>

                    {edit === true && <div className="flex mb-4">
                        <div className="w-1/2">
                            <Header6 textAlign={'left'} paddingTop={8} paddingBottom={8}>{t('haccp.haccp.comment')}</Header6>
                        </div>
                        <div className="w-1/4">
                            <Header6 textAlign={'left'} paddingTop={8} paddingBottom={8}>{measurement.comment}</Header6>
                        </div>
                        <div className="w-1/4 flex">
                            <Header6 className={'flex'} textAlign={'right'} paddingTop={8} paddingBottom={8} display={'block'}>
                                <button onClick={() => setEdit(!edit)}>
                                    <img className={'h-4 object-right'} src={require('../../assets/icons/pencil.svg')} alt={'Edit comment'} />
                                </button>
                            </Header6>
                        </div>
                    </div>}

                    {edit === false && <TextArea
                        placeholder={t('haccp.haccp.comment')}
                        inputChange={(text) => changeComment(text)}
                        type={'text'}
                        value={measurement.comment}
                        error={error ? error.error ? error.error.password ? error.error.password[0] : false : false : false}
                    />}

                    {edit === false && <PrimaryButton
                        text={t('haccp.settings.saveChanges')}
                        submit={submitForm}
                        disabled={false}
                    />}
                </div>
            </div >
        )
    else return null
}

export default HeatmapDetails;