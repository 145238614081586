import API from '../utils/API';


export const getAccessToken = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = await localStorage.getItem('accessToken');
            token ? resolve(token) : reject('No access token found');
        }
        catch (e) {
            reject(e);
        }
    })
}

export const removeTokens = () => {
    return new Promise((resolve, reject) => {
        try {
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('accessToken');
            localStorage.removeItem('idToken');

            resolve({
                message: 'Tokens removed!'
            })
        }
        catch (e) {
            reject(e);
        }
    })
}

//Register a new user account
export const registerUser = (name, surname, email, password) => {
    const body = {
        'email': email,
        'password': password,
        'name': name,
        'family_name': surname
    }

    return new Promise(async (resolve, reject) => {
        API.post('register', body)
            .then(res => {
                resolve(res);
            })
            .catch(e => {
                reject(e.response);
            })
    })
}

//Request refreshed access token by providing a valid refresh token
export const getNewAccessToken = (refreshToken) => {
    const body = {
        'refresh_token': refreshToken
    }

    return new Promise(async (resolve, reject) => {
        API.post('refresh_token', body)
            .then(res => {
                resolve(res);
            })
            .catch(e => {
                reject(e.response);
            })
    })
}

//Log the user out
export const logoutUser = () => {
    localStorage.removeItem('i18nextLng');
    removeTokens()
        .catch(e => console.log(e));
}

//Log the user in with the entered credentials and get refresh/access/id tokens in response
export const loginUser = (email, password) => {
    const body = {
        'username': email,
        'password': password
    }

    return new Promise(async (resolve, reject) => {
        API.post('auth', body)
            .then(res => {
                resolve(res);
            })
            .catch(e => {
                if (e.response)
                    reject(e.response);
                else
                    reject();
            })
    })
}

//Get Reset Password Link on E mail
export const resetPassword = (email) => {
    const body = {
        "username": email
    }

    return new Promise(async (resolve, reject) => {
        API.post('forgot_password', body)
            .then(res => {
                resolve(res);
            })
            .catch(e => {
                reject(e.response);
            })
    })
}


//Get Reset Password Link on E mail
export const changePassword = (email, code, password) => {
    const body = {
        "username": email,
        "password": password,
        "code": code
    }

    return new Promise(async (resolve, reject) => {
        API.put('confirm_forgot_password ', body)
            .then(res => {
                resolve(res);
            })
            .catch(e => {
                reject(e.response);
            })
    })
}

export const getProfile = () => {
    return new Promise(async (resolve, reject) => {
        API.get('profile')
        .then(res => {
            resolve(res.data);
        })
        .catch(e => {
            reject(e.response);
        })
    })
}