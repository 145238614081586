import React, { useState, useEffect } from 'react';
import Rodal from 'rodal';
import Comment from '../../../pages/comment'

//Redux
import { useDispatch } from 'react-redux';
import { openCommentModal } from '../../../redux/actions/haccp';

// include styles
import 'rodal/lib/rodal.css';

const DataTile = ({ value, index, lastRow, threshold }) => {
    //Redux
    const dispatch = useDispatch();

    const headerColumn = index === 0 ? true : false

    const [visible, setVisible] = useState(false);
    const [checkValue, setValue] = useState(null);

    const [comment, setComment] = useState(null);
    const [id, setId] = useState(null);

    useEffect(() => {
        if (value !== null && value !== undefined) {
            if (value.value !== null && value.value !== "Date" && !(value.value[2] === ':' || value.value[1] === ':')) {
                setValue(parseFloat(value.value))
            } else {
                setValue(value.value)
            }

            if (value.comment !== null) {
                setComment(value.comment)
            }

            if (value.id !== null) {
                setId(value.id)
            }
        }

    }, [setValue, setComment, setId, value])

    const handleOpenModal = (id) => {
        dispatch(openCommentModal(id));
    }

    if (headerColumn || lastRow) {
        if (headerColumn) {
            return (
                <td className={'border-0 text-text-onyx opacity-38 font-sans text-xs font-normal text-right py-2 pr-4 pl-2 '} >
                    {checkValue}
                </td >
            )
        } else {
            return (
                <td className={'border-0 text-text-onyx opacity-38 font-sans text-xs font-normal p-2 text-center'} >
                    {checkValue}
                </td >
            )
        }

    } else {
        if (checkValue === null) {
            return (
                <td className={'relative opacity-5 font-sans text-xs font-normal text-center bg-text-onyx-5 p-2 border border-text-onyx-19'}
                    style={{minWidth: 33, maxWidth: 33}}
                >
                    -
                    {id !== null && <Rodal visible={visible} onClose={() => setVisible()} height={750} closeOnEsc={true}>
                        <Comment checkValue={checkValue} />
                    </Rodal>}
                    { comment !== null && <span className={'triangle-topright'} />}
                </td>
            )
        } else if (checkValue >= parseInt(threshold[0]) && checkValue <= parseInt(threshold[1])) {
            return (
                <td className={'relative text-text-onyx opacity-87 font-sans text-xs font-bold p-2 text-center border border-text-onyx-19 width: 33px'}
                    onClick={() => handleOpenModal(id)}
                    style={{minWidth: 33, maxWidth: 33}}
                >
                    {checkValue}
                    <Rodal visible={visible} onClose={() => setVisible()} height={750} closeOnEsc={true}>
                        <Comment checkValue={checkValue} />
                    </Rodal>
                    {comment !== null && <span className={'triangle-topright'} />}
                </td>
            )
        } else {
            return (
                <td className={'relative text-magenta-100 font-sans text-xs font-bold p-2 text-center border border-text-onyx-19 bg-text-onyx-5'}
                    onClick={() => handleOpenModal(id)}
                    style={{minWidth: 33, maxWidth: 33}}
                >
                    {checkValue}
                    <Rodal visible={visible} onClose={() => setVisible()} height={750} closeOnEsc={true}>
                        {/* <Comment comment={comment} location={location} group={group} sensor={sensor} alarm_type={alarmType} date={date} time={time} temperature={checkValue} id={id} rule={rule} company_id={companyId}/> */}
                        <Comment checkValue={checkValue} />
                    </Rodal>
                    { comment !== null && <span className={'triangle-topright'} />}
                </td>
            )
        }
    }
}

export default DataTile;