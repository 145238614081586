import React from 'react';

import Header1 from '../elements/Header1';
import Header5 from '../elements/Header5';

// Translation
import { useTranslation } from 'react-i18next';
import LanguageSelect from '../components/inputs/LanguageSelect';
import Header6 from '../elements/Header6';

const ChangePasswordSuccessPage = ({ history }) => {

    //Translation
    const { t } = useTranslation();

    return (
        <div className={'flex w-screen h-screen sm:flex-col-reverse sm:h-auto'}>
            <div className={'bg-white px-24 md:px-6 h-screen w-2/5 md:w-3/6 flex items-center justify-center sm:w-full relative'}>
                <div>
                    <LanguageSelect />
                    <h1 className={'text-indigo-100'} style={{ fontSize: 72, fontWeight: 'bold', textAlign: 'center' }}>kelvyn</h1>
                    <Header1 color={'text-indigo-100'}>{t('portal')}</Header1>

                    <Header5 textAlign={'text-left'} color={'login-secondary'} paddingTop={10} additionalClass={'text-center'} >{t('login.resetSuccess.followLinkChange')}</Header5>

                    <div className={`flex justify-center`}>
                        <p onClick={() => history.push('/login')} className={`underline inline-block cursor-pointer pt-5`}>
                            <Header6 additionalClass={'text-center'} color={'indigo-100'}>{t('login.create.back')}</Header6>
                        </p>
                    </div>
                </div>
            </div>

            <div className={'h-screen w-3/5 md:w-3/6 sm:h-16 sm:w-full'}>
                <img src={require('../assets/images/iceberg.jpg')} alt={'Iceberg background'} className={'h-screen w-full object-cover sm:h-16 sm:object-none'} />
            </div>
        </div>
    )
}

export default ChangePasswordSuccessPage;