const initialState = {
    alarm: 'ALARM ALARM',
    alarm_table_ordering: 'sensor_id'
}

const alarmReducer = (state = initialState, action) => {
    if (action.type === 'SET_ALARM') {
        return Object.assign({}, state, {
            alarm: action.title
        })
    }

    if (action.type === 'SET_ALARM_TABLE_ORDERING') {
        return Object.assign({}, state, {
            alarm_table_ordering: action.column
        }) 
    }
    else return state;
}

export default alarmReducer;