import React, { useState } from 'react';

import Header1 from '../elements/Header1';
import Header6 from '../elements/Header6';
import Body from '../elements/Body';
import TextInput from '../components/inputs/TextInput';
import PrimaryButton from '../components/buttons/PrimaryButton';
import LanguageSelect from '../components/inputs/LanguageSelect';
import {
    useLocation
} from "react-router-dom";

// Translation
import { useTranslation } from 'react-i18next';

import { withRouter } from 'react-router-dom'

//Services
import { changePassword } from '../services/auth';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ChangePasswordPage = ({ history }) => {
    let query = useQuery();
    const [error, setError] = useState(null);
    const [password, setPassword] = useState('');

    //Translation
    const { t } = useTranslation();

    //Functions
    const submitForm = () => {
        try {
            changePassword(query.get("email"), query.get("confirmation_code"), password)
                .then(result => {
                    history.push('/change-password-success');
                    setError(null);
                })
                .catch(e => {
                    console.error(e);
                    if (e) {
                        if (e.data) {
                            setError(e.data)
                        }
                    }
                })
        }
        catch (e) {
            console.log(e);
        }
    }

    return (
        <div className={'flex w-screen h-screen sm:flex-col-reverse sm:h-auto'}>
            <div className={'bg-white px-24 md:px-6 h-screen w-2/5 md:w-3/6 flex items-center justify-center sm:w-full relative'}>
                <LanguageSelect />
                <div>
                    <LanguageSelect />
                    <h1 className={'text-indigo-100'} style={{ fontSize: 72, fontWeight: 'bold', textAlign: 'center' }}>kelvyn</h1>
                    <Header1 color={'text-indigo-100'}>{t('portal')}</Header1>

                    <Header6 textAlign={'text-left'} color={'indigo-100'} paddingTop={60} paddingBottom={20} style={{ fontSize: 15, width: 432 }}>{t('login.resetPassword.resetHeader')}</Header6>

                    <Body textAlign={'text-left'} color={'login-secondary'}>{t('login.changePassword.enterPassword')}</Body>

                    <TextInput
                        value={password}
                        placeholder={t('login.create.password')}
                        type={'password'}
                        inputChange={(text) => setPassword(text)}
                        error={error ? error.error ? error.error.password ? t('login.login.error.password') : false : false : false}
                    />

                    <PrimaryButton
                        text={t('login.changePassword.changeHeader')}
                        submit={submitForm}
                        disabled={!password}
                    />

                    {error ? error.error ? Object.values(error.error).length > 0 ?
                        Object.entries(error.error).map((value) => {
                            let translationsArray = []
                            value[1].map((value) => {
                                switch (value) {
                                    case 'Password did not conform with policy: Password must have a symbol character, a uppercase, a lowercase and a number':
                                        translationsArray.push('login.login.error.passwordConform')
                                        break;
                                    case 'The password should be at least 8 characters!':
                                        translationsArray.push('login.login.error.passwordLengthNumber')
                                        break;
                                    default:
                                        break;
                                }
                                return value;
                            })
                            return translationsArray.map((translation) => {
                                return <div className={'flex flex-row items-center mt-3'}>
                                    <svg
                                        className={'h-5 text-orange-100'}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                        preserveAspectRatio="xMinYMin"
                                    >
                                        <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                                    </svg>
                                    <Header6 color={'orange-100'}>{t(translation)}</Header6>
                                </div>
                            })
                        })

                        : false : false : false
                    }
                    <div className={`flex justify-center`}>
                        <p onClick={() => history.push('/login')} className={`underline inline-block cursor-pointer`}>
                            <Header6 additionalClass={'text-center'} color={'indigo-100'}>{t('login.resetPassword.back')}</Header6>
                        </p>
                    </div>
                </div>
            </div>

            <div className={'h-screen w-3/5 md:w-3/6 sm:h-16 sm:w-full'}>
                <img src={require('../assets/images/iceberg.jpg')} className={'h-screen w-full object-cover sm:h-16 sm:object-none'} alt={'Iceberg'} />
            </div>
        </div>
    )
}

export default withRouter(ChangePasswordPage);