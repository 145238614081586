import React, { useState, useEffect } from 'react';
import moment from 'moment';

import SelectionLabel from '../../elements/DatePicker/SelectionLabel';
import DateTextInput from '../../elements/DatePicker/DateTextInput';

import Calendar from 'react-calendar'
import './Styles/Calendar.css';

const DatePicker = ({ placeholder, anchor, onValueChange, disableFuture }) => {
    //State
    const [input, setInput] = useState('');
    const [showCalendar, setShowCalendar] = useState(false);
    const [selectedRange, setSelectedRange] = useState([moment(moment(new Date())).add(-7, 'days'), moment(new Date())]);
    const [selectedLabel, setSelectedLabel] = useState(null);

    //Effect
    useEffect(() => {
        if (selectedRange) {
            const today = moment(new Date());

            if (
                selectedRange[0].format('YYYY/MMMM/DD') === moment(today).format('YYYY/MMMM/DD') &&
                selectedRange[1].format('YYYY/MMMM/DD') === moment(today).format('YYYY/MMMM/DD')
            ) {
                setSelectedLabel('Today');
                setInput('Today');
            }
            else if (
                selectedRange[0].format('YYYY/MMMM/DD') === moment(today).add(-7, 'days').format('YYYY/MMMM/DD') &&
                selectedRange[1].format('YYYY/MMMM/DD') === today.format('YYYY/MMMM/DD')
            ) {
                setSelectedLabel('Last 7 Days');
                setInput('Last 7 Days');
            }
            else if (
                selectedRange[0].format('YYYY/MMMM/DD') === moment(today).add(-1, 'month').format('YYYY/MMMM/DD') &&
                selectedRange[1].format('YYYY/MMMM/DD') === today.format('YYYY/MMMM/DD')
            ) {
                setSelectedLabel('Last 30 Days');
                setInput('Last 30 Days');
            }
            else if (
                selectedRange[0].format('YYYY/MMMM/DD') === moment(today).add(-1, 'year').format('YYYY/MMMM/DD') &&
                selectedRange[1].format('YYYY/MMMM/DD') === today.format('YYYY/MMMM/DD')
            ) {
                setSelectedLabel('This Year');
                setInput('This Year');
            }
            else {
                setSelectedLabel('Custom');
                setInput(`${selectedRange[0].format('DD MMM YYYY')} - ${selectedRange[1].format('DD MMM YYYY')}`);
            }
        }
    }, [selectedRange]);

    useEffect(() => {
        if (selectedLabel) {
            const today = moment(new Date());

            if (selectedLabel === 'Today') {
                setSelectedRange([today, today]);
            }
            else if (selectedLabel === 'Last 7 Days') {
                setSelectedRange([moment(today).add(-7, 'days'), today]);
            }
            else if (selectedLabel === 'Last 30 Days') {
                setSelectedRange([moment(today).add(-1, 'month'), today]);
            }
            else if (selectedLabel === 'This Year') {
                setSelectedRange([moment(today).add(-1, 'year'), today]);
            }
        }
    }, [selectedLabel]);

    useEffect(() => {
        onValueChange([selectedRange[0].format('YYYY-MM-DD'), selectedRange[1].format('YYYY-MM-DD')])
        // eslint-disable-next-line
    }, [selectedRange]);

    //Functions
    const selectLabel = (selection) => {
        setSelectedLabel(selection);
        setShowCalendar(false);
    }

    const changeRange = (range) => {
        const start = new Date(range[0]);
        const end = new Date(range[1]);

        setSelectedRange([moment(start), moment(end)]);
        setShowCalendar(false);
    }

    return (
        <>
            <div className={`relative flex flex-col`}>
                <div onClick={() => setShowCalendar(true)}>
                    <DateTextInput
                        value={input}
                        placeholder={placeholder}
                        inputChange={text => setInput(text)}
                    />
                </div>

                <div className={`absolute z-30 bg-text-white top-56px md:top-auto sm:bottom-0 ${anchor === 'left' ? 'left-0' : anchor === 'right' ? 'right-0' : false} w-782px md:w-400px sm:w-full ${showCalendar ? 'flex' : 'hidden'} shadow`}>
                    <div className={`shadow w-1/5`}>
                        <SelectionLabel
                            text={'Today'}
                            selected={selectedLabel}
                            setSelection={(item) => selectLabel(item)}
                        />
                        <SelectionLabel
                            text={'Last 7 Days'}
                            selected={selectedLabel}
                            setSelection={(item) => selectLabel(item)}
                        />
                        <SelectionLabel
                            text={'Last 30 Days'}
                            selected={selectedLabel}
                            setSelection={(item) => selectLabel(item)}
                        />
                        <SelectionLabel
                            text={'This Year'}
                            selected={selectedLabel}
                            setSelection={(item) => selectLabel(item)}
                        />
                        <SelectionLabel
                            text={'Custom'}
                            selected={selectedLabel}
                            setSelection={(item) => selectLabel(item)}
                        />
                    </div>

                    <div className={`p-6 flex flex-col w-4/5 md:hidden`} >
                        <Calendar
                            value={selectedRange}
                            maxDate={disableFuture ? new Date() : false}
                            onChange={e => changeRange(e)}
                            selectRange
                            showNeighboringMonth={false}
                            showDoubleView={true}
                            showNavigation={true}
                            formatShortWeekday={(locale, date) => { return (date.toString().slice(0, 1)) }}
                        />
                    </div>

                    <div className={`p-6 hidden flex-col w-4/5 md:flex`} >
                        <Calendar
                            value={selectedRange}
                            maxDate={disableFuture ? new Date() : false}
                            onChange={e => changeRange(e)}
                            selectRange
                            showNeighboringMonth={false}
                            showDoubleView={false}
                            showNavigation={true}
                            formatShortWeekday={(locale, date) => { return (date.toString().slice(0, 1)) }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default DatePicker;

