import React from 'react';
import CommentCellPage from '../../pages/comment-cell'

const BodyCell = ({ value, header, id, onCommentChange, withSensor, isHeader }) => {
    if (withSensor === true) {
        return (
            <td className={`text-left p-3 pl-0 ${isHeader ? 'opacity-60' : 'opacity-87'} `}>{value}</td>
        )
    } else {
        return (
            <>
                {header !== 'Comment' ?
                    <td className={`flex-1 md:flex-none sm:flex-none text-left text-text-onyx  ${isHeader ? 'opacity-60' : 'opacity-87'}  p-3 pl-0`}>{value}</td>
                    : <CommentCellPage
                        id={id}
                        value={value}
                        onCommentChange={(comment, commentId) => {
                            onCommentChange(comment, commentId);
                        }}
                    />
                }
            </>
        )
    }

}

export default BodyCell;