export const menuItems = [
    // {
    //     title: 'Dashboard',
    //     slug: '/dashboard',
    //     icon: 'thermometer.svg'
    // },
    {
        title: 'menu.haccp',
        slug: '/haccp',
        icon: 'haccp-icon.svg'
    },
    {
        title: 'menu.alarmLog',
        slug: '/alarm-log',
        icon: 'alarmlog-icon.svg'
    },
    {
        title: 'menu.sensor',
        slug: '/sensor',
        icon: 'line-graph.svg'
    },
    // {
    //     title: 'Configuration',
    //     slug: '/configuration',
    //     icon: 'configuration.svg',
    //     children: [
    //         {
    //             title: 'Sensors'
    //         },
    //         {
    //             title: 'Notifications'
    //         },
    //         {
    //             title: 'Users'
    //         }
    //     ]
    // },
    // {
    //     title: 'Account Settings',
    //     slug: '/account-settings',
    //     icon: 'my-account.svg'
    // },
]
