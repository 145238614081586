import React from 'react';

// Arrow icon
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrow-down.svg';

const HeaderCell = ({ value, color, opacity, onOrdering, ordering }) => {
    const handleOrdering = () => {
        onOrdering(value)
    }
    return (
        <th onClick={() => handleOrdering()} className={`flex flex-1 text-${color ? color : 'indigo-100'} text-left opacity-${opacity ? opacity : 1} font-sans items-center text-base font-bold py-3`}>
            {value} {value === (ordering ? ordering.name : null) ?  <ArrowDownIcon className={`w-2 h-2 ml-2 mr-2 fill-current indigo-100`} /> : ''}
        </th>
    )

}

export default HeaderCell;