import React, { useState, useEffect } from 'react';
//Components
import Dropdown from '../components/inputs/Dropdown';
import Card from '../components/dashboard/Card';
//Elements
import Header2 from '../elements/Header2';
import Header6 from '../elements/Header6';
import PrimaryButton from '../components/buttons/PrimaryButton';
import Loader from '../elements/Loader';
//Services
import { getAllLocations } from '../services/location';
import { getAllSensors } from '../services/sensor';
// Translation
import { useTranslation } from 'react-i18next';
//Assets
import FilterIcon from '../assets/icons/settings.svg';
import CloseIcon from '../assets/icons/close-tooltip.svg';
//Modal
import AddNewCreateEdit from '../components/modals/AddNewCreateEdit';
import Paginator from '../components/table/Paginator';

//Redux
import { useSelector } from 'react-redux';

const SensorPage = () => {
  //Translation
  const { t } = useTranslation();

  //State
  const [availableLocations, setAvailableLocations] = useState([]);
  const [availableGroups, setAvailableGroups] = useState([]);
  const [availableSensors, setAvailableSensors] = useState([]);
  const [setAvailableGroupsForRender] = useState([]);
  const [loading, setLoading] = useState(true);

  const pageRefresh = useSelector((state) => state.sensor.sensorPageRefresh);

  //Filter states
  const [setLocation] = useState('');
  const [group, setGroup] = useState('');

  //Toogle states
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const [showAddNewCreateEdit, setShowAddNewCreateEdit] = useState(false);

  //Paginator
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [prevBool, setPreviousBool] = useState(false);
  const [nextBool, setNextBool] = useState(false);

  //Functions
  const changeLocation = (value) => {
    setLocation(value);
    setAvailableGroups([]);
  };

  const changeGroup = (value) => {
    setGroup(value.value);
    console.log(group);
  };

  //Effect
  useEffect(() => {
    getAllLocations()
      .then((res) => {
        if (res.length > 0) {
          let tempLocations = [];
          res.map((location) => {
            tempLocations.push({
              id: location.id,
              value: location.id,
              label: location.name,
              companyId: location.company_id,
              groups: location.location_groups,
            });
            return tempLocations;
          });
          setAvailableLocations(tempLocations);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    if (availableLocations.length > 0) {
      let tempGroups = [];
      availableLocations.map((item) => {
        /*Since there will be 1 location_group per Sensor*/
        tempGroups.push({
          id: item.groups[0].id,
          value: item.groups[0].id,
          label: item.groups[0].name,
          companyId: item.groups[0].company_id,
          location: item.groups[0].location,
        });
        return tempGroups;
      });
      setAvailableGroups(tempGroups);
    }
  }, [availableLocations]);

  useEffect(() => {
    let cardGroups = ['Priority', 'Unassigned'];
    getAllSensors()
      .then((res) => {
        // 1 - priority
        // 2 - unassigned
        // 3 - n location names
        const preparedSensor =
          res &&
          res.data &&
          res.data.results.map((sensor) => {
            const maxBattery =
              sensor.max_level !== null && sensor.max_level !== undefined
                ? sensor.max_level
                : 3600;
            sensor.battery_level =
              sensor.newest_measurement_battery &&
              sensor.newest_measurement_battery.current_level
                ? parseInt(
                    (sensor.newest_measurement_battery.current_level * 100) /
                      maxBattery
                  )
                : '';
            sensor.is_active === false || sensor.battery_level < 16
              ? (sensor.class = 'unasssigned')
              : (sensor.class = sensor.location.name);
            cardGroups.push(sensor.location && sensor.location.name);
            return sensor;
          });
        setAvailableSensors(preparedSensor);
        setAvailableGroupsForRender(cardGroups);
        setLoading(false);
        setTotalPages(res.data.count);
        res.data.previous === null
          ? setPreviousBool(false)
          : setPreviousBool(true);
        res.data.next === null ? setNextBool(false) : setNextBool(true);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, [
    setAvailableSensors,
    setAvailableGroupsForRender,
    setTotalPages,
    setPreviousBool,
    setNextBool,
    pageRefresh,
  ]);

  const handleCloseAddNewCreateEdit = () => {
    setShowAddNewCreateEdit(false);
  };

  const handleAddNewCreateEdit = () => {
    setShowAddNewCreateEdit(true);
  };

  return (
    <div className="w-full">
      {loading ? (
        <div className={'flex justify-center h-screen items-center'}>
          <Loader show size={'w-20 h-20'} />
        </div>
      ) : (
        <>
          <AddNewCreateEdit
            show={showAddNewCreateEdit}
            groups={availableGroups}
            onClose={() => handleCloseAddNewCreateEdit()}
          />
          <div
            className={`py-10 px-16 sm:py-4 sm:px-4 w-full`}
            style={{ backgroundColor: '#fdfdfe' }}
          >
            <div className={`flex md:justify-between`}>
              <Header2>{t('dashboard.sensorPage')}</Header2>
              <div
                className={`w-3/4 grid grid-cols-3 gap-1 ml-8 items-center justify-center md:hidden sm:hidden`}
              >
                <Dropdown
                  placeholderText={t('haccp.haccp.location')}
                  options={availableLocations}
                  onValueChange={(value) => changeLocation(value)}
                />
                <Dropdown
                  placeholderText={t('haccp.haccp.group')}
                  options={availableGroups}
                  onValueChange={(value) => changeGroup(value)}
                />
                <PrimaryButton
                  text={t('dashboard.addNew')}
                  submit={() => handleAddNewCreateEdit()}
                  disabled={false}
                />
              </div>
              <div
                className={`w-12 h-12 rounded-full bg-indigo-6 hidden sm:flex md:flex justify-center align-center ml-3 mr-3 shadow cursor-pointer`}
                onClick={() => setShowMobileFilters(!showMobileFilters)}
              >
                <img
                  src={FilterIcon}
                  alt={'Settings button'}
                  className={`h-full w-4`}
                />
              </div>
              <div
                className={`fixed bg-text-white z-10 md:right-0 md:h-full md:w-1/3 sm:w-full sm:h-auto sm:bottom-0 p-4 shadow flex-col ${
                  showMobileFilters ? 'hidden sm:flex md:flex' : 'hidden'
                }`}
              >
                <div
                  className={`absolute right-0 mr-4 cursor-pointer`}
                  onClick={() => setShowMobileFilters(false)}
                >
                  <img src={CloseIcon} alt={'Close button'} className={`w-4`} />
                </div>
                <Header6 textAlign={'left'} color={'indigo-100'}>
                  Filter
                </Header6>
                <div style={{ height: 20 }} />
                <Dropdown
                  smMaxWidth={'100%'}
                  placeholderText={'Location'}
                  options={availableLocations}
                  onValueChange={(value) => changeLocation(value)}
                />
                <div style={{ height: 15 }} />
                <Dropdown
                  smMaxWidth={'100%'}
                  placeholderText={'Group'}
                  options={availableGroups}
                  onValueChange={(value) => changeGroup(value)}
                />
                <div className={`hidden sm:flex mt-4`}>
                  <PrimaryButton
                    text={'Done'}
                    submit={() => setShowMobileFilters(false)}
                  />
                </div>
              </div>
            </div>
            {availableSensors.length > 0 ? (
              <>
                <div
                  className={`grid grid-cols-3 gap-3 md:grid-cols-1 sm:grid-cols-1`}
                >
                  <React.Fragment>
                    {availableSensors.map((sensor, index) => {
                      return (
                        <React.Fragment key={`cards_${index}`}>
                          <Card
                            temperature={
                              sensor.newest_measurement_temp
                                ? parseInt(sensor.newest_measurement_temp)
                                : null
                            }
                            sensorName={sensor.name}
                            sensorId={sensor.sensor_id}
                            location={sensor.location && sensor.location.name}
                            group={
                              sensor.location_group &&
                              sensor.location_group.name
                            }
                            battery={sensor.battery_level}
                            threshold={sensor.threshold}
                          />
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                </div>
                <div className={'mt-6'}>
                  <Paginator
                    totalItems={totalPages}
                    currentPage={currentPage}
                    onPrevious={(page) => setCurrentPage(page)}
                    onNext={(page) => setCurrentPage(page)}
                    onLast={(page) => setCurrentPage(page)}
                    onFirst={(page) => setCurrentPage(page)}
                    prev={prevBool}
                    next={nextBool}
                  ></Paginator>
                </div>
              </>
            ) : (
              <div
                className={
                  'py-10 px-16 sm:py-4 sm:px-4 w-full overflow-x-hidden shadow mt-10 rounded-lg'
                }
              >
                {t('dashboard.error.noData')}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SensorPage;
