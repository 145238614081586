import React, { useState } from 'react';

//Elements
import SubHeader from '../../elements/SubHeader';
import Header6 from '../../elements/Header6';

//Modules
import Select from 'react-select'

import { ReactComponent as ArrowRightIcon } from '../../assets/icons/arrow-right.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrow-down.svg';

const Dropdown = ({ options, placeholderText, disabled, inputError, collapsed, onValueChange, smMaxWidth, resetDropdown }) => {
    //Select styles
    const customSelectStyles = {
        indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none'
        }),
        control: (provided, state) => ({
            ...provided,
            boxShadow: 0,
            backgroundColor: collapsed ? 'rgba(0,0,0,0)' : 'rgba(209, 209, 209, 0.21)',
            border:
                collapsed ? 'solid 1px rgba(13, 13, 13, 0.19)'
                    : inputError ? 'solid 1px #fa9500'
                        : state.isFocused ? 'solid 1px #1600aa'
                            : 0,
            '&:hover': {
                border: collapsed ? 0
                    : inputError ? 'solid 1px #fa9500'
                        : state.isFocused ? 'solid 1px #1600aa'
                            : 0,
                borderBottom: collapsed ? 'solid 1px rgba(13, 13, 13, 0.19)'
                    : inputError ? 'solid 1px #fa9500'
                        : state.isFocused ? 'solid 1px #1600aa'
                            : 0,
                backgroundColor: collapsed ? 'rgba(72, 68, 242, 0.03)' : 'none',
                '.css-zt0lhb-placeholder': collapsed ? { color: '#1600aa' } : {}
            },
            borderTopWidth: collapsed ? 0 : 1,
            borderLeftWidth: collapsed ? 0 : 1,
            borderRightWidth: collapsed ? 0 : 1,
            borderRadius: collapsed ? 0 : 4,
            width: '100%',
            height: 46,
        }),
        container: (provided) => ({
            ...provided,
            border: 0,
        }),
        menu: (provided) => ({
            ...provided,
        }),
        option: (provided, state) => ({
            ...provided,
            borderRadius: 4,
            color: state.isSelected ? '#1600aa' : 'rgba(13, 13, 13, 0.87)',
            backgroundColor: state.isSelected ? 'rgba(72, 68, 242, 0.06)' : 'transparent',
            '&:hover': { backgroundColor: 'rgba(72, 68, 242, 0.03)' },
            fontWeight: state.isSelected ? 'bold' : '400'
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'rgba(13, 13, 13, 0.38)'
        }),
        singleValue: (provided) => ({
            ...provided,
            paddingTop: 15
        }),
        input: (provided) => ({
            ...provided,
            paddingTop: input || selection ? 15 : 0
        }),
    }

    const DropdownIndicator = props => {
        return (
            <div {...props}>
                {collapsed ?
                    <ArrowRightIcon fill="rgba(13, 13, 13, 0.6)" stroke="rgba(13, 13, 13, 0.6)" className={`w-2 h-2 ml-2 mr-2`} />
                    :
                    <ArrowDownIcon fill="rgba(13, 13, 13, 0.6)" stroke="rgba(13, 13, 13, 0.6)" className={`w-2 h-2 ml-2 mr-2`} />
                }
            </div>
        );
    };

    //State
    const [selection, setSelection] = useState(null);
    const [input, setInput] = useState('');

    //Functions
    const handleSelect = (e) => {
        setSelection(e);
        if (onValueChange)
            onValueChange(e);
    }

    return (
        <div className={`w-full ${smMaxWidth ? smMaxWidth : 'max-w-xs'} rounded bg-transparent`}>
            {selection || input ?
                <SubHeader>{placeholderText}</SubHeader>
                : false
            }

            <Select
                options={options}
                components={{ DropdownIndicator }}
                styles={customSelectStyles}
                placeholder={placeholderText}
                value={resetDropdown? '':selection}
                onChange={(e) => handleSelect(e)}
                inputValue={input}
                onInputChange={e => setInput(e)}
                isDisabled={disabled}
            />

            {inputError ?
                <div className={'flex flex-row items-center mt-3'}>
                    <svg
                        className={'h-5 w-5 text-orange-100'}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                    </svg>
                    <Header6 textAlign={'left ml-1'} color={'orange-100'}>{inputError}</Header6>
                </div>
                : false
            }
        </div>
    )
}

export default Dropdown;