import React from 'react';

import Header1 from '../elements/Header1';
import Header6 from '../elements/Header6';

// Translation
import { useTranslation } from 'react-i18next';
import LanguageSelect from '../components/inputs/LanguageSelect';


const RegistrationSuccessPage = () => {

    //Translation
    const { t } = useTranslation();

    return (
        <div className={'flex w-screen h-screen sm:flex-col-reverse sm:h-auto'}>
            <div className={'bg-white px-24 md:px-6 h-screen w-2/5 md:w-3/6 flex items-center justify-center sm:w-full relative'}>
                <div>
                    <LanguageSelect />
                    <h1 className={'text-indigo-100'} style={{ fontSize: 72, fontWeight: 'bold', textAlign: 'center' }}>kelvyn</h1>
                    <Header1 color={'text-indigo-100'}>{t('portal')}</Header1>

                    <div className={'flex justify-center'}>
                        <img className={'h-16 w-16 mt-24'} src={require('../assets/icons/email-fill.svg')} alt={'email illustration'} />
                    </div>

                    <Header6 paddingTop={'6'} color={'indigo-80 opacity-87 pt-6'}>{t('login.registrationSuccess.almostDone')}</Header6>

                    <Header6 paddingTop={'6'} color={'text-onyx opacity-87 pt-3'}>{t('login.registrationSuccess.checkEmail')}</Header6>

                </div>
            </div>

            <div className={'h-screen w-3/5 md:w-3/6 sm:h-16 sm:w-full'}>
                <img src={require('../assets/images/iceberg.jpg')} className={'h-screen w-full object-cover md:object-fill sm:h-16 sm:object-none'} alt={'Iceberg'} />
            </div>
        </div>
    )
}

export default RegistrationSuccessPage;