import React from 'react';

const Toggle = ({ enabled, onToggle }) => {
    //Functions
    const handleToggleClick = () => {
        onToggle();
    }

    return (
        <div
            className={`relative w-12 h-3 ${enabled ? 'bg-indigo-38' : 'bg-text-onyx-25'} rounded-full cursor-pointer`}
            onClick={() => handleToggleClick()}
        >
            <div className={`absolute w-6 h-6 rounded-full top-neg5px right-0 ${enabled ? 'bg-indigo-100' : 'bg-text-onyx-87 left-0'}`} />
        </div>
    )
}

export default Toggle;