import React, { useState } from 'react';
import BodyCell from './BodyCell'

import { useSelector } from 'react-redux';

const TableRow = ({ rows, headers, color, size, sign, details, onCommentChange, withSensor }) => {
    //State
    const [lastUpdatedCommentId, setLastUpdatedCommentId] = useState(null);
    const [lastUpdatedComment, setLastUpdatedComment] = useState(null);

    const editedCommentId = useSelector(state => state.haccp.editedCommentId);
    const editedComment = useSelector(state => state.haccp.editedComment);

    let tmpHeaders = headers;

    if (size === 'small') {
        tmpHeaders = tmpHeaders.filter(e => e !== sign)
        if (withSensor === true) {
            tmpHeaders.push('Comment')
        }
    }

    const headersConcat = tmpHeaders.map((value) => {
        return value.replace(/\s/g, "")
    })

    const handleClick = (row) => {
        if (details) {
            details(row);
        }
    }
    if (size === 'small') {
        if (withSensor === true) {
            return (
                <React.Fragment>
                    {headersConcat.map((header, index) => (
                        <React.Fragment key={`small_hearder_row_${index}`}>
                            <tr className={`text-${color ? color : 'onyx'} text-base ${rows.read ? 'font-bold' : 'font-normal'} py-3`}>
                                <BodyCell key={header} value={header} withSensor={withSensor} isHeader={true} />
                                <BodyCell
                                    key={rows[header]}
                                    value={header === 'Comment' ? rows['id'] === editedCommentId ? editedComment : rows['id'] === lastUpdatedCommentId ? lastUpdatedComment : rows[header] : rows[header]}
                                    header={header}
                                    id={rows['id']}
                                    withSensor={withSensor}
                                    isHeader={false}
                                />
                            </tr>
                        </React.Fragment>
                    ))}
                </React.Fragment>
            )
        }
        else {
            return (
                <React.Fragment>
                    {headersConcat.map((header, index) => (
                        <React.Fragment key={`small_hearder_row_${index}`}>
                            <tr onClick={() => handleClick()} className={`text-${color ? color : 'onyx'} text-base ${rows.read ? 'font-bold' : 'font-normal'} py-3`}>
                                <BodyCell key={header} value={header} isHeader={true} />
                                <BodyCell
                                    key={rows[header]}
                                    value={header === 'Comment' ? rows['id'] === editedCommentId ? editedComment : rows['id'] === lastUpdatedCommentId ? lastUpdatedComment : rows[header] : rows[header]}
                                    header={header}
                                    id={rows['id']}
                                    onCommentChange={(comment, commentId) => {
                                        setLastUpdatedCommentId(commentId);
                                        setLastUpdatedComment(comment);
                                    }}
                                />
                            </tr>
                        </React.Fragment>
                    ))}
                </React.Fragment>
            )
        }
    } else {
        return (
            <React.Fragment>
                {rows.map((row, index) => (
                    <React.Fragment key={`big_header_row_${index}`}>
                        <tr onClick={() => handleClick(row)} className={`flex justify-between w-full text-${color ? color : 'onyx'} text-base ${row.read ? 'font-bold' : 'font-normal'} py-3`}>
                            {Object.entries(row).map(([key, value]) => {
                                return headersConcat.includes(key) === true ?
                                    <BodyCell
                                        key={key}
                                        value={key === 'Comment' ? row['id'] === editedCommentId ? editedComment : row['id'] === lastUpdatedCommentId ? lastUpdatedComment : value : value}
                                        //value={header === 'id' ? rows['id'] === editedCommentId ? editedComment : rows['id'] === lastUpdatedCommentId ? lastUpdatedComment : rows[header] : rows[header]}
                                        header={key}
                                        id={row['id']}
                                        onCommentChange={(comment, commentId) => {
                                            setLastUpdatedCommentId(commentId);
                                            setLastUpdatedComment(comment);
                                        }}
                                    />
                                    : null
                            }
                            )}
                        </tr>
                    </React.Fragment>
                ))}
            </React.Fragment>
        )
    }

}

export default TableRow;