import moment from 'moment';

export const newPrepareHeatmap = (list) => {
    return new Promise((resolve) => {
        const temp = []

        list.map(item => {
            temp.push({
                comment: item.comment,
                temperature: item.temperature,
                time: {
                    year: moment.tz(item.time, 'Etc/GMT+0').format('YYYY'),
                    month: parseInt(moment.tz(item.time, 'Etc/GMT+0').format('MM')),
                    day: moment.tz(item.time, 'Etc/GMT+0').format('DD'),
                    hours: moment.tz(item.time, 'Etc/GMT+0').format('HH'),
                    minutes: moment.tz(item.time, 'Etc/GMT+0').format('mm'),
                },
                date: moment.tz(item.time, 'Etc/GMT+0').format('DD.MM.YYYY'),
                hours: moment.tz(item.time, 'Etc/GMT+0').format('HH:mm'),
                alarm_type: item.alarm_type,
                company_id: item.company_id,
                location: null,
                group: null,
                sensor: item.sensor_id,
                id: item.id,
                rule: item.rule
            })
            return item;
        })
        return temp.length > 0 ? resolve(temp) : resolve([]);
    })
}


export const prepareMeasurementTableHeatMapData = (list) => {
    return new Promise((resolve, reject) => {
        const temp = []

        list.map((measure) => {
            temp.push({
                id: measure.id,
                Date: moment.tz(measure.time, 'Etc/GMT+0').format('DD MMM YYYY'),
                Time: moment.tz(measure.time, 'Etc/GMT+0').format('HH:mm'),
                AlarmType: measure.alarm_type,
                Comment: measure.comment ? measure.comment : '',
                read: false
            })

            return measure;
        })
        temp.length > 0 ? resolve(temp) : resolve([]);
    })
}

export const prepareHeatmapTableData = (measurements) => {
    const uniqueSensors = getUniqueSensors(measurements)
    const result = uniqueSensors.map((sensor) => {
        const sensorData = []
        measurements.map((measure) => {

            if (measure.sensor_id === sensor) {
                sensorData.push({
                    id: measure.id,
                    Group: "NO GROUPS RETURNED YET",
                    Sensor: measure.sensor_id,
                    Date: measure.time,
                    AlarmType: measure.alarm_type,
                    Temperature: measure.temperature,
                    read: false
                })
            }
            return measure
        })
        return { sensor_id: sensor, sensor_data: sensorData }
    })
    return result
}

const getUniqueSensors = (measurements) => {
    return measurements.map((mes) => {
        return mes.sensor_id
    }).filter((v, i, a) => a.indexOf(v) === i)
}