import React, { useState } from 'react';

//Elements
import SubHeader from '../../elements/SubHeader';
import Header6 from '../../elements/Header6';

const DateTextInput = ({ placeholder, disabled, inputError, type, inputChange, value }) => {
    //State
    const [showPassword, setShowPassword] = useState(false);

    //Functions
    const handleInputChange = (e) => {
        inputChange(e.target.value);
    }

    return (
        <div className={'max-w-md rounded bg-transparent'}>
            {value ?
                <SubHeader>{placeholder}</SubHeader>
                : false
            }

            <div className={'relative'}>
                <input
                    disabled={disabled}
                    className={`px-3 py-3 ${value ? 'pt-5 pb-1' : 'pt-3'} leading-5 rounded bg-surface-smoke bg-opacity-21 w-full text-base ${disabled ? 'text-text-onyx opacity-38' : 'text-text-onyx'} placeholder-text-onyx placeholder-opacity-60 border ${inputError ? 'border-orange-100' : 'border-transparent'} focus:border-indigo-100`}
                    placeholder={placeholder}
                    value={value}
                    onChange={e => handleInputChange(e)}
                    type={type ?
                        type === 'password' && showPassword ?
                            'text'
                            : type
                        : 'text'
                    }
                />
                {type === 'password' ?
                    <img
                        className={'w-5 h-5 absolute bottom-12.5px right-10px cursor-pointer color-text-onyx opacity-60'}
                        src={showPassword ?
                            require('../../assets/icons/visible.svg')
                            : require('../../assets/icons/not-visible.svg')
                        }
                        alt={'show/hide password icon'}
                        onClick={() => setShowPassword(!showPassword)}
                    />
                    : false
                }
            </div>

            {inputError ?
                <div className={'flex flex-row items-center mt-3'}>
                    <svg
                        className={'h-5 text-orange-100'}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                    </svg>
                    <Header6 color={'orange-100'}>{inputError}</Header6>
                </div>
                : false
            }
        </div>
    )
}

export default DateTextInput;
