import React, { useState } from 'react';

//React Components
import NavigationMenu from '../components/navigation/Menu';
import LoginPage from '../pages/login';
import RegistrationPage from '../pages/create-account';
import RegistrationSuccessPage from '../pages/registration-success';
import ResetPasswordPage from '../pages/reset-password';
import ChangePasswordPage from '../pages/change-password';
import ResetSuccessPage from '../pages/reset-success';
import TablePage from '../pages/table';
import HaccpPage from '../pages/haccp';
import HaccpPage2 from '../pages/haccp-2';
import AlarmLogPage from '../pages/alarm-log';
import WelcomePage from '../pages/welcome';
import DashboardPage from '../pages/dashboard';
import ChangePasswordSuccessPage from '../pages/change-password-success';
import NotFoundPage from '../pages/not-found';
import { PrivateRoute } from './PrivateRoute';
//Modules
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { AuthContext } from '../context/useAuth';
import SensorPage from '../pages/sensor';

function App() {
  const accessToken = localStorage.getItem('accessToken');
  const [authToken, setAuthToken] = useState(accessToken);

  const setTokens = (data) => setAuthToken(data);

  //State
  const [menuExpanded, setMenuExpanded] = useState(true);

  return (
    <AuthContext.Provider value={{ authToken, setAuthToken: setTokens }}>
      <Router>
        <Switch>
          <Route path="/login" exact component={LoginPage} />
          <Route path="/registration" exact component={RegistrationPage} />
          <Route
            path="/registration-success"
            exact
            component={RegistrationSuccessPage}
          />
          <Route path="/forgot-password" exact component={ResetPasswordPage} />
          <Route path="/change-password" exact component={ChangePasswordPage} />
          <Route path="/reset-success" exact component={ResetSuccessPage} />
          <Route
            path="/change-password-success"
            exact
            component={ChangePasswordSuccessPage}
          />
          <>
            <NavigationMenu
              onToggleExpand={(status) => setMenuExpanded(status)}
            />
            <div
              className={`flex sm:ml-0 sm:pt-2 ${
                menuExpanded ? 'ml-64 md:ml-56' : 'ml-20 sm:pt-10'
              }`}
            >
              <Route path="/table" exact component={TablePage} />
              <PrivateRoute path="/" exact component={DashboardPage} />
              <PrivateRoute path="/welcome" exact component={WelcomePage} />
              <PrivateRoute path="/haccp" exact component={HaccpPage} />
              <PrivateRoute path="/haccp-2" exact component={HaccpPage2} />
              <PrivateRoute path="/alarm-log" exact component={AlarmLogPage} />
              <PrivateRoute path="/not-found" exact component={NotFoundPage} />
              <PrivateRoute path="/sensor" exact component={SensorPage} />
            </div>
          </>
        </Switch>
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
