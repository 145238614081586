import React from 'react';
import TableHeader from './TableHeaders';
import TableRow from './TableRow';

const Table = ({ headers, rows, sign, sort, size, onOrdering, ordering, details, withSensor }) => {
    return (
        <div className={'mt-6 lg:overflow-x-hidden md:overflow-x-scroll sm:overflow-x-scroll w-full'}>
            <table className={'flex sm:hidden md:hidden flex-col'}>
                <thead className={'w-full'}>
                    <TableHeader headers={headers} onOrdering={onOrdering} ordering={ordering} />
                </thead>
                <tbody>
                    <TableRow rows={rows} headers={headers} details={details} />
                </tbody>
            </table>

            {withSensor === true ?
                   rows.map((row, index) => (
                    <React.Fragment key={`table_row_${index}`}>
                        <table className={'hidden md:block sm:block'}>
                            <thead>
                                <TableHeader headers={headers} row={row} size={'small'} sign={sign} withSensor={withSensor} />
                            </thead>
                            <tbody>
                                <TableRow rows={row} headers={headers} size={'small'} sign={sign} withSensor={withSensor}/>
                            </tbody>
                        </table>
                    </React.Fragment>
                ))
                :
                rows.map((row, index) => (
                    <React.Fragment key={`table_row_${index}`}>
                        <table className={'hidden md:block sm:block'}>
                            <thead>
                                <TableHeader headers={headers} row={row} size={'small'} sign={sign} />
                            </thead>
                            <tbody>
                                <TableRow rows={row} headers={headers} size={'small'} sign={sign} />
                            </tbody>
                        </table>
                    </React.Fragment>
                ))}

        </div>
    )

}

export default Table;
