import React from 'react';

const Header2 = (props) => {
    return (
        <h1
            className={`text-center ${props.disabled ? 'text-text-onyx opacity-38' : props.color ? props.color : 'text-text-onyx'} font-bold font-sans text-3xl`}
        >
            {props.children}
        </h1>
    )
}

export default Header2;
