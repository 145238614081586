import React, { useState } from 'react';

//React components
import Header1 from '../elements/Header1';
import Header6 from '../elements/Header6';
import TextInput from '../components/inputs/TextInput';
import PrimaryButton from '../components/buttons/PrimaryButton';
import LanguageSelect from '../components/inputs/LanguageSelect';

// Translation
import { useTranslation } from 'react-i18next';

//Services
import { loginUser } from '../services/auth';

//Modules
import { withRouter } from 'react-router-dom';

const LoginPage = ({ history }) => {
  //State
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  //Translation
  const { t } = useTranslation();

  //Functions
  const submitForm = () => {
    try {
      loginUser(email, password)
        .then((result) => {
          if (result.status === 200) {
            setError(null);
            localStorage.setItem(
              'accessToken',
              result.data.credentials.access_token
            );
            localStorage.setItem('idToken', result.data.credentials.id_token);
            localStorage.setItem(
              'refreshToken',
              result.data.credentials.refresh_token
            );

            if (localStorage.getItem('notFirstLogin')) {
              history.push('/haccp');
            } else {
              localStorage.setItem('notFirstLogin', 'true');
              history.push('/welcome');
            }
          }
        })
        .catch((e) => {
          console.error(e);
          if (e && e.data) setError(e.data);
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={'flex w-screen h-screen sm:flex-col-reverse sm:h-auto'}>
      <div
        className={
          'bg-white px-24 md:px-6 h-screen w-2/5 md:w-3/6 flex items-center justify-center sm:w-full relative'
        }
      >
        <LanguageSelect />
        <div>
          <h1
            className={'text-indigo-100'}
            style={{ fontSize: 72, fontWeight: 'bold', textAlign: 'center' }}
          >
            kelvyn
          </h1>
          <Header1 color={'text-indigo-100'}>{t('portal')}</Header1>

          <TextInput
            value={email}
            placeholder={t('login.login.email')}
            inputChange={(text) => setEmail(text)}
            error={
              error
                ? error.error
                  ? error.error.username
                    ? t('login.login.error.username')
                    : false
                  : false
                : false
            }
          />

          <TextInput
            value={password}
            placeholder={t('login.login.password')}
            type={'password'}
            inputChange={(text) => setPassword(text)}
            error={
              error
                ? error.error
                  ? error.error.password
                    ? t('login.login.error.password')
                    : false
                  : false
                : false
            }
          />
          {/* && Object.keys(error.error)[0] !== 'password' && Object.keys(error.error)[0] !== 'username' {t('login.login.error.errorOccured')}*/}
          {error
            ? error.error
              ? Object.values(error.error).length > 0
                ? Object.entries(error.error).map((value) => {
                    let translationsArray = [];
                    value[1].map((value) => {
                      switch (value) {
                        case 'Password did not conform with policy: Password must have a symbol character, a uppercase, a lowercase and a number':
                          translationsArray.push(
                            'login.login.error.passwordConform'
                          );
                          break;
                        case 'The password should be at least eight characters!':
                          translationsArray.push(
                            'login.login.error.passwordLength'
                          );
                          break;
                        case 'Please enter a valid email address!':
                          translationsArray.push(
                            'login.login.error.validEmail'
                          );
                          break;
                        case 'User does not exist.':
                          translationsArray.push(
                            'login.login.error.userDoesNotExist'
                          );
                          break;
                        case 'Incorrect username or password.':
                          translationsArray.push(
                            'login.login.error.incorrectUsernameOrPassword'
                          );
                          break;
                        default:
                          break;
                      }
                      return value;
                    });
                    return translationsArray.map((translation) => {
                      return (
                        <div className={'flex flex-row items-center mt-3'}>
                          <svg
                            className={'h-5 text-orange-100'}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          <Header6 textAlign={'left'} color={'orange-100 ml-1'}>
                            {t(translation)}
                          </Header6>
                        </div>
                      );
                    });
                  })
                : false
              : false
            : false}

          <PrimaryButton
            text={t('login.login.login')}
            submit={submitForm}
            disabled={!email || !password}
          />

          <div className={`justify-center`}>
            <div
              onClick={() => history.push('/forgot-password')}
              className={`underline mb-4 cursor-pointer`}
            >
              <Header6 textAlign={'center'} color={'indigo-100'}>
                {t('login.login.forgotPassword')}
              </Header6>
            </div>
            <div
              onClick={() => history.push('/registration')}
              className={`underline  cursor-pointer ml-1`}
            >
              <Header6 textAlign={'center'} color={'indigo-100'}>
                {t('login.login.register')}
              </Header6>
            </div>
          </div>
        </div>
      </div>

      <div className={'h-screen w-3/5 md:w-3/6 sm:h-16 sm:w-full'}>
        <img
          src={require('../assets/images/iceberg.jpg')}
          alt={'Iceberg background'}
          className={'h-screen w-full object-cover sm:h-16 sm:object-none'}
        />
      </div>
    </div>
  );
};

export default withRouter(LoginPage);
