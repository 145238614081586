export const setAlarm = (title) => {
    return {
        type: 'SET_ALARM',
        title: title
    }
}

export const setAlarmTableOrdering = (column) => {
    return {
        type: 'SET_ALARM_TABLE_ORDERING',
        column: column
    }
}
