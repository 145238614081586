import React, { useState, useEffect } from 'react';

//Components
import Table from '../components/table/Table';
import Dropdown from '../components/inputs/Dropdown';
import DatePicker from '../components/selectionControls/DatePicker';

//Elements
import Header2 from '../elements/Header2';
import Header6 from '../elements/Header6';
import PrimaryButton from '../components/buttons/PrimaryButton';

//Services
import { getAllLocations, getAllSensors } from '../services/location';
import { getAlarmsLog } from '../services/alarm';

//Modules
import moment from 'moment-timezone';

//Assets
import FilterIcon from '../assets/icons/settings.svg';
import CloseIcon from '../assets/icons/close-tooltip.svg';

// Translation
import { useTranslation } from 'react-i18next';

// Pagination
import Paginator from '../components/table/Paginator';
import AlarmLogsDetails from '../components/modals/AlarmLogsDetails';

const AlarmLogPage = () => {
  //table columns
  const headers = ['Group', 'Sensor', 'Date', 'Alarm Type', 'Temperature'];

  //Translation
  const { t } = useTranslation();

  //State
  const [availableLocations, setAvailableLocations] = useState([]);
  const [availableGroups, setAvailableGroups] = useState([]);
  const [availableSensors, setAvailableSensors] = useState([]);
  const [location, setLocation] = useState('');
  const [group, setGroup] = useState('');
  const [sensor, setSeonsor] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [showMobileFilters, setShowMobileFilters] = useState(false);

  const [tableDataSource, setTableDataSource] = useState([]);

  const [showAlarmLogDetailsModal, setshowAlarmLogDetailsModal] = useState(
    false
  );
  const [row, setRow] = useState({});

  //Paginator
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [prevBool, setPreviousBool] = useState(false);
  const [nextBool, setNextBool] = useState(false);

  // Sort by
  const [ordering, setOrdering] = useState({
    name: 'Sensor',
    value: 'sensor_id',
  });

  //Effect
  useEffect(() => {
    getAllLocations()
      .then((res) => {
        if (res.length > 0) {
          let tempLocations = [];
          res.map((location) => {
            tempLocations.push({
              id: location.id,
              value: location.id,
              label: location.name,
              companyId: location.company_id,
              groups: location.location_groups,
            });
            return tempLocations;
          });
          setAvailableLocations(tempLocations);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    getAllSensors()
      .then((res) => {
        if (res.length > 0) {
          let tempSensors = [];
          res.map((sensor) => {
            tempSensors.push({
              id: sensor.id,
              value: sensor.id,
              label: sensor.name,
              companyId: sensor.company_id,
              group: sensor.location_group,
              location: sensor.location,
              active: sensor.is_active,
              sensorId: sensor.sensor_id,
            });
            return tempSensors;
          });
          setAvailableSensors(tempSensors);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  useEffect(() => {
    if (location.groups && location.groups.length > 0) {
      let tempGroups = [];
      location.groups.map((group) => {
        tempGroups.push({
          id: group.id,
          value: group.id,
          label: group.name,
          companyId: group.companyId,
          location: group.location,
        });
        return tempGroups;
      });
      setAvailableGroups(tempGroups);
    }
  }, [location]);

  //Functions
  const changeLocation = (value) => {
    setLocation(value);
    setAvailableGroups([]);
  };

  const changeGroup = (value) => {
    setGroup(value.value);
    setCurrentPage(1);
  };

  const changeSensor = (value) => {
    setSeonsor(value.label);
    setCurrentPage(1);
  };

  const changeRange = (selection) => {
    setStartDate(selection[0]);
    setEndDate(selection[1]);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (
      sensor &&
      group &&
      currentPage &&
      startDate &&
      endDate &&
      ordering.value
    ) {
      getAlarmsLog(
        sensor,
        group,
        currentPage,
        startDate,
        endDate,
        ordering.value
      ).then((res) => {
        let tmpTableDataSource = [];
        moment.locale(localStorage.getItem('i18nextLng'));
        res.results.map((log) => {
          tmpTableDataSource.push({
            Group: log.sensor.location_group_name,
            Sensor: log.sensor.sensor_name,
            Date: moment
              .tz(log.created_at, 'Europe/Berlin')
              .format('DD MMM YYYY'),
            AlarmType: log.alarm_type,
            Comment: log.comment,
            Temperature:
              log.temperature === '' || log.temperature === null
                ? ''
                : parseFloat(log.temperature).toFixed(2).toString() + ' °',
          });
          return log;
        });
        setTableDataSource(tmpTableDataSource);
        setTotalPages(res.count);
        res.previous === null ? setPreviousBool(false) : setPreviousBool(true);
        res.next === null ? setNextBool(false) : setNextBool(true);
      });
    }
  }, [
    setTableDataSource,
    sensor,
    group,
    currentPage,
    setTotalPages,
    setPreviousBool,
    setNextBool,
    startDate,
    endDate,
    ordering.value,
  ]);

  const handleOrdering = (value) => {
    switch (value) {
      case 'Sensor':
        setOrdering({ name: 'Sensor', value: 'sensor_id' });
        break;
      case 'Group':
        setOrdering({ name: 'Group', value: 'group_id' });
        break;
      default:
        setOrdering({ name: 'Sensor', value: 'sensor_id' });
        break;
    }
  };

  const handleCloseAlarmLogDetails = () => {
    setshowAlarmLogDetailsModal(false);
  };

  const handleAlarmLogDetails = (row) => {
    setRow(row);
    setshowAlarmLogDetailsModal(true);
  };

  return (
    <>
      <AlarmLogsDetails
        show={showAlarmLogDetailsModal}
        onClose={() => handleCloseAlarmLogDetails()}
        row={row}
      />
      <div
        className={`py-10 px-16 sm:py-4 sm:px-4 w-full`}
        style={{ backgroundColor: '#fdfdfe' }}
      >
        <div className={`flex md:justify-between`}>
          <Header2>{t('alarmLog.alarmLog')}</Header2>
          <div
            className={`w-3/4 grid grid-cols-4 gap-3 ml-8 md:hidden sm:hidden`}
          >
            <Dropdown
              placeholderText={t('haccp.haccp.location')}
              options={availableLocations}
              onValueChange={(value) => changeLocation(value)}
            />
            <Dropdown
              placeholderText={t('haccp.haccp.group')}
              options={availableGroups}
              onValueChange={(value) => changeGroup(value)}
            />
            <Dropdown
              placeholderText={t('haccp.haccp.sensor')}
              options={[{ label: 'All Sensors', value: 'All Sensors' }].concat(
                availableSensors
              )}
              onValueChange={(value) => changeSensor(value)}
            />
            <DatePicker
              disableFuture
              placeholder={'Date range'}
              anchor={'right'}
              onValueChange={(selection) => changeRange(selection)}
            />
          </div>
          <div
            className={`w-12 h-12 rounded-full bg-indigo-6 hidden sm:flex md:flex justify-center align-center ml-3 mr-3 shadow cursor-pointer`}
            onClick={() => setShowMobileFilters(!showMobileFilters)}
          >
            <img
              src={FilterIcon}
              alt={'Settings button'}
              className={`h-full w-4`}
            />
          </div>
          <div
            className={`fixed bg-text-white z-10 md:right-0 md:h-full md:w-1/3 sm:w-full sm:h-auto sm:bottom-0 p-4 shadow flex-col ${
              showMobileFilters ? 'hidden sm:flex md:flex' : 'hidden'
            }`}
          >
            <div
              className={`absolute right-0 mr-4 cursor-pointer`}
              onClick={() => setShowMobileFilters(false)}
            >
              <img src={CloseIcon} alt={'Close button'} className={`w-4`} />
            </div>
            <Header6 textAlign={'left'} color={'indigo-100'}>
              Filter
            </Header6>
            <div style={{ height: 20 }} />
            <Dropdown
              smMaxWidth={'100%'}
              placeholderText={'Location'}
              options={availableLocations}
              onValueChange={(value) => changeLocation(value)}
            />
            <div style={{ height: 15 }} />
            <Dropdown
              smMaxWidth={'100%'}
              placeholderText={'Group'}
              options={availableGroups}
              onValueChange={(value) => changeGroup(value)}
            />
            <div style={{ height: 15 }} />
            <Dropdown
              smMaxWidth={'100%'}
              placeholderText={'Sensor'}
              options={availableSensors}
              onValueChange={(value) => changeSensor(value)}
            />
            <div style={{ height: 15 }} />
            <DatePicker
              disableFuture
              placeholder={'Date range'}
              anchor={'right'}
              onValueChange={(selection) => changeRange(selection)}
            />
            <div className={`hidden sm:flex mt-4`}>
              <PrimaryButton
                text={'Done'}
                submit={() => setShowMobileFilters(false)}
              />
            </div>
          </div>
        </div>

        <div>
          <div
            className={`py-10 px-16 sm:py-4 sm:px-4 w-full overflow-x-hidden shadow mt-10 rounded-lg`}
            style={{ backgroundColor: '#fdfdfe' }}
          >
            {tableDataSource.length < 1 ? (
              <div>{t('alarmLog.noData')}</div>
            ) : (
              <>
                <Table
                  headers={headers}
                  rows={tableDataSource}
                  sign={'Sensor'}
                  size={'big'}
                  onOrdering={(value) => handleOrdering(value)}
                  ordering={ordering}
                  details={(row) => handleAlarmLogDetails(row)}
                  withSensor={true}
                ></Table>
                <div className={'mt-6 mr-24 relative'}>
                  <Paginator
                    totalItems={totalPages}
                    currentPage={currentPage}
                    onPrevious={(page) => setCurrentPage(page)}
                    onNext={(page) => setCurrentPage(page)}
                    onLast={(page) => setCurrentPage(page)}
                    onFirst={(page) => setCurrentPage(page)}
                    prev={prevBool}
                    next={nextBool}
                  ></Paginator>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AlarmLogPage;
