import React, { useState } from 'react';

//Elements
import Header6 from '../../elements/Header6';

const Checkbox = (props) => {
    //State
    const [checked, setChecked] = useState(props.inputChange);

    //Functions
    const handleInputChange = (e) => {
        setChecked(e.target.checked)
        props.inputChange(e.target.checked);
    }

    return (
        <div className={'max-w-xs py-3 my-6 rounded bg-transparent'}>
            <div className={'flex'}>
                <input
                    className={'border active:border-indigo-100 mr-1'}
                    type={'checkbox'}
                    disabled={props.disabled}
                    checked={checked}
                    onChange={e => handleInputChange(e)}
                />
                <Header6 textAlign={'left pl-3'} color={props.disabled ? 'text-onyx opacity-38' : 'text-onyx opacity-87'}>{props.children}</Header6>
            </div>

            {props.inputError ?
                <div className={'flex flex-row items-center mt-3'}>
                    <svg
                        className={'h-5 text-orange-100 flex-shrink-0 '}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                    </svg>
                    <Header6 color={'orange-100 pl-3'}>{props.inputError}</Header6>
                </div>
                : false
            }
        </div>
    )
}

export default Checkbox;
