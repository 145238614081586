import React, { useState, useEffect } from 'react'

// Translation
import { useTranslation } from 'react-i18next';
// import Dropdown from '../inputs/Dropdown';

const Paginator = ({ totalItems, currentPage, onPrevious, onNext, onFirst, onLast, prev, next, onPageSize }) => {
    const [noPages, setNoPages] = useState(1)

    //Translation
    const { t } = useTranslation();

    //availablePageSize
    // const options = [{ value: 5, label: '5' }, { value: 10, label: '10' }, { value: 25, label: '25' }, { value: 50, label: '50' }]

    useEffect(() => {
        setNoPages(Math.ceil(totalItems / 50))
    }, [setNoPages, noPages, totalItems])

    const handlePrevious = () => {
        onPrevious(currentPage - 1)
    }

    const handleNext = () => {
        onNext(currentPage + 1)
    }

    const handleFirst = () => {
        onFirst(1)
    }

    const handleLast = () => {
        onLast(noPages)
    }

    // const handlePageSize = (value) => {
    //     onPageSize(value)
    // }
    return (
        <>

            <div className="flex items-center justify-end z-20">
                {/* <div className={`w-32`}>
                    <Dropdown
                        placeholderText={t('paginator.pageSize')}
                        options={options}
                        onValueChange={value => handlePageSize(value.value)}
                    />
                </div> */}
                <button className={`px-2`} onClick={() => handleFirst()}><img src={require('../../assets/icons/arrow-left-circle-thin.svg')} alt={t('paginator.firstPage')}></img></button>
                <button className={`px-2`} onClick={() => handlePrevious()} disabled={!prev}><img src={require('../../assets/icons/angel-left-circle-thin.svg')} alt={t('paginator.previousPage')}></img></button>
                <span className={`px-2`}>{currentPage}</span>
                <button className={`px-2`} onClick={() => handleNext()} disabled={!next}><img src={require('../../assets/icons/angel-right-circle-thin.svg')} alt={t('paginator.nextPage')}></img></button>
                <button className={`px-2`} onClick={() => handleLast()}><img src={require('../../assets/icons/arrow-right-circle-thin.svg')} alt={t('paginator.lastPage')}></img></button>
            </div>
        </>
    )
}

export default Paginator;