import React from 'react';

//Elements
import Header4 from '../../elements/Header4';
import Header5 from '../../elements/Header5';
import Header6 from '../../elements/Header6';

//Assets
import CloseButtonIcon from '../../assets/icons/close-tooltip.svg';

//Modules
import moment from 'moment';

// Translation
import { useTranslation } from 'react-i18next';

const AlarmLogsDetails = ({ show, onClose, row }) => {
    //Translation
    const { t } = useTranslation();

    //Functions
    const closeModal = () => {
        onClose();
    }
    return (
        <div
        className={`fixed items-center z-20 h-full top-0 right-0 left-256px md:left-0 bg-text-onyx-25 ${show ? 'flex' : 'hidden'} flex-row justify-center`}
        >
            <div className={`w-5/12 z-30 md:w-full bg-text-white rounded p-8 overflow-y-scroll`} style={{ height: '80%' }}>
                <img
                    src={CloseButtonIcon}
                    alt={'Close modal button'}
                    className={`w-6 h-6 float-right cursor-pointer`}
                    onClick={() => closeModal()}
                />
                <Header4 textAlign={'left'} color={'indigo-100'}>{row['Sensor']}</Header4>

                <div className={'mt-8 flex items-center justify-between'}>
                    <div className={`w-11/12`}>
                        <Header5 textAlign={'left'} color={'indigo-100'}>{t('alarmLog.alarmDetailsModal.overview')}</Header5>
                    </div>
                </div>

                <div className={'mt-8 flex items-center'}>
                    <div className={`w-1/2`}>
                        <Header6 className={'pt-8 pb-8'} textAlign={'left'} color={'text-onyx-87'}>{t('alarmLog.alarmDetailsModal.location')}</Header6>
                    </div>
                    <div className={`w-1/2`}>
                        <Header6 className={'pt-8 pb-8'} textAlign={'left'} color={'text-onyx-87'}>{row['Location']}</Header6>
                    </div>
                </div>

                <div className={'mt-8 flex items-center'}>
                    <div className={`w-1/2`}>
                        <Header6 className={'pt-8 pb-8'} textAlign={'left'} color={'text-onyx-87'}>{t('alarmLog.alarmDetailsModal.group')}</Header6>
                    </div>
                    <div className={`w-1/2`}>
                        <Header6 className={'pt-8 pb-8'} textAlign={'left'} color={'text-onyx-87'}>{row['Group']}</Header6>
                    </div>
                </div>

                <div className={'mt-8 flex items-center'}>
                    <div className={`w-1/2`}>
                        <Header6 className={'pt-8 pb-8'} textAlign={'left'} color={'text-onyx-87'}>{t('alarmLog.alarmDetailsModal.sensor')}</Header6>
                    </div>
                    <div className={`w-1/2`}>
                        <Header6 className={'pt-8 pb-8'} textAlign={'left'} color={'text-onyx-87'}>{row['Sensor']}</Header6>
                    </div>
                </div>

                <div className={'mt-8 flex items-center'}>
                    <div className={`w-1/2`}>
                        <Header6 className={'pt-8 pb-8'} textAlign={'left'} color={'text-onyx-87'}>{t('alarmLog.alarmDetailsModal.alarmType')}</Header6>
                    </div>
                    <div className={`w-1/2`}>
                        <Header6 className={'pt-8 pb-8'} textAlign={'left'} color={'text-onyx-87'}>{row['AlarmType']}</Header6>
                    </div>
                </div>

                <div className={'mt-8 flex items-center'}>
                    <div className={`w-1/2`}>
                        <Header6 className={'pt-8 pb-8'} textAlign={'left'} color={'text-onyx-87'}>{t('alarmLog.alarmDetailsModal.temperature')}</Header6>
                    </div>
                    <div className={`w-1/2`}>
                        <Header6 className={'pt-8 pb-8'} textAlign={'left'} color={'text-onyx-87'}>{row['Temperature']}</Header6>
                    </div>
                </div>

                <div className={'mt-8 flex items-center justify-between'}>
                    <div className={`w-11/12`}>
                        <Header5 textAlign={'left'} color={'indigo-100'}>{t('alarmLog.alarmDetailsModal.time')}</Header5>
                    </div>
                </div>

                <div className={'mt-8 flex items-center'}>
                    <div className={`w-1/2`}>
                        <Header6 className={'pt-8 pb-8'} textAlign={'left'} color={'text-onyx-87'}>{t('alarmLog.alarmDetailsModal.date')}</Header6>
                    </div>
                    <div className={`w-1/2`}>
                        <Header6 className={'pt-8 pb-8'} textAlign={'left'} color={'text-onyx-87'}>{moment(row['Date']).format('DD MM YYYY')}</Header6>
                    </div>
                </div>

                <div className={'mt-8 flex items-center'}>
                    <div className={`w-1/2`}>
                        <Header6 className={'pt-8 pb-8'} textAlign={'left'} color={'text-onyx-87'}>{t('alarmLog.alarmDetailsModal.time')}</Header6>
                    </div>
                    <div className={`w-1/2`}>
                        <Header6 className={'pt-8 pb-8'} textAlign={'left'} color={'text-onyx-87'}>{moment(row['Date']).format('hh:mm:ss')}</Header6>
                    </div>
                </div>

                <div className={'mt-8 flex items-center'}>
                    <div className={`w-1/2`}>
                        <Header6 className={'pt-8 pb-8'} textAlign={'left'} color={'text-onyx-87'}>{t('alarmLog.alarmDetailsModal.timeframe')}</Header6>
                    </div>
                    <div className={`w-1/2`}>
                        <Header6 className={'pt-8 pb-8'} textAlign={'left'} color={'text-onyx-87'}>not known</Header6>
                    </div>
                </div>

                <div className={'mt-8 flex items-center justify-between'}>
                    <div className={`w-11/12`}>
                        <Header5 textAlign={'left'} color={'indigo-100'}>{t('alarmLog.alarmDetailsModal.notifications')}</Header5>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AlarmLogsDetails;