import React from 'react';

//Modules
import { Slider, Rail, Handles, Tracks } from "react-compound-slider";
import { SliderRail, Handle, Track } from "./SliderComponents";

//Constants
const sliderStyle = {
    position: "relative",
    width: "100%"
};

const domain = [-20, 65];


const RangeSlider = ({ onValueChange, defaultValues, disabled }) => {
    //Functions
    const onChange = (e) => {
        onValueChange(e);
    }

    return (
        <div className={`w-full h-6 mt-8`}>
            <Slider
                disabled={disabled}
                mode={2}
                step={1}
                domain={domain}
                rootStyle={sliderStyle}
                onChange={e => onChange(e)}
                values={defaultValues}
            >
                <Rail>
                    {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
                </Rail>
                <Handles>
                    {({ handles, getHandleProps }) => (
                        <div className={"slider-handles"}>
                            {handles.map((handle) => (
                                <Handle
                                    key={handle.id}
                                    handle={handle}
                                    domain={domain}
                                    getHandleProps={getHandleProps}
                                />
                            ))}
                        </div>
                    )}
                </Handles>
                <Tracks left={false} right={false}>
                    {({ tracks, getTrackProps }) => (
                        <div className="slider-tracks">
                            {tracks.map(({ id, source, target }) => (
                                <Track
                                    key={id}
                                    source={source}
                                    target={target}
                                    getTrackProps={getTrackProps}
                                />
                            ))}
                        </div>
                    )}
                </Tracks>
            </Slider>
        </div>
    )
}

export default RangeSlider;