import React from 'react';

//Elements
import Header6 from '../../elements/Header6';
import Header5 from '../../elements/Header5';

const MenuItem = ({ icon, title, selected, expanded, notifications }) => {
    return (
        <div className={`flex items-center px-8 h-16 hover:bg-indigo-3 cursor-pointer bg-${selected ? 'indigo-6' : 'transparent'}`}>
            <div className={'flex w-6 justify-center'}>
                {icon ?
                    <img className={'h-4'} src={require(`../../assets/icons/${icon}`)} alt={`menu item - ${title}`} />
                    : false
                }
            </div>
            {expanded ?
                selected ?
                    <Header5 color={`${selected ? 'indigo-100' : 'text-onyx'} opacity-87 pl-5`}>{title}</Header5>
                    : <Header6 color={`${selected ? 'indigo-100' : 'text-onyx'} opacity-87 pl-5`}>{title}</Header6>
                : false
            }
            {notifications ?
                expanded ?
                    <div className={'rounded-full w-4 h-4 bg-magenta-100 ml-3 flex justify-center items-center'}>
                        <p className={'text-xs text-text-white'}>{notifications}</p>
                    </div>
                    :
                    <div className={'rounded-full w-2 h-2 bg-magenta-100 absolute right-0 mr-4'} />
                : false
            }
        </div>
    )
}

export default MenuItem;
