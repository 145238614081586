import React from 'react';

const SubHeader = (props) => {
    return (
        <h6 className={'text-xs text-indigo-100 absolute px-3 t-3'}>
            {props.children}
        </h6>
    )
}

export default SubHeader;
