import API from '../utils/APIDomain';

export const getAlarmsLog = (sensor, group, currentPage, startDate, endDate, ordering) => {
    const sensorChecked = sensor === 'All Sensors' ? '' : sensor

    return new Promise(async (resolve, reject) => {
        API.get(`/alarms/log?sensor_id=${sensorChecked}&location_group=${group}&page=${currentPage}&ordering=${ordering}&created_at_after=${startDate}&created_at_before=${endDate}`)
            .then(res => {
                if (res.data)
                    resolve(res.data);
            })
            .catch(e => {
                reject(e.response);
            })
    })
}