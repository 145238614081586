import React from 'react';

//Elements
import Header4 from '../../elements/Header4';
import Header6 from '../../elements/Header6';

//Assets
import CloseButtonIcon from '../../assets/icons/close-tooltip.svg';
import PencilIcon from '../../assets/icons/pencil.svg';

//Modules
import moment from 'moment';

// Translation
import { useTranslation } from 'react-i18next';

//Redux
import { useSelector } from 'react-redux';

const HaccpSettings = ({ show, sensor, onClose, onEdit, rule, lastMeasurement }) => {
    //Translation
    const { t } = useTranslation();

    //Redux
    const editedSensorName = useSelector(state => state.haccp.editedSensorName);
    const editedSensorId = useSelector(state => state.haccp.editedSensorId);
    const editedSensorThreshold = useSelector(state => state.haccp.editedSensorThreshold);
    const editedSensorMeasurementTimes = useSelector(state => state.haccp.editedSensorMeasurementTimes);

    //Functions
    const closeModal = () => {
        onClose();
    }

    const openEdit = () => {
        onEdit();
    }

    return (
        <div
            className={`fixed md:fixed z-10 h-full md:h-screen top-0 right-0 left-256px md:left-0 bg-text-onyx-25 ${show ? 'flex' : 'hidden'} flex-row justify-center md:items-end`}
        >
            <div className={`w-5/12 mt-17.5vh md:w-full bg-text-white rounded z-20 p-8`} style={{ height: "max-content" }}>
                <img
                    src={CloseButtonIcon}
                    alt={'Close modal button'}
                    className={`w-6 h-6 float-right cursor-pointer`}
                    onClick={() => closeModal()}
                />
                <Header4 textAlign={'left'} color={'indigo-100'}>{t('haccp.settings.haccpSettings')}</Header4>

                <div className={'mt-8 flex items-center justify-between'}>
                    <div className={`w-11/12`}>
                        <Header6 textAlign={'left'} color={'indigo-100'}>{t('haccp.settings.name')}</Header6>
                        <Header6 textAlign={'left'} color={'text-onyx-87'}>
                            {sensor
                                ? sensor.sensorId === editedSensorId
                                    ? editedSensorName
                                    : sensor.label
                                : false}
                        </Header6>
                    </div>
                    <div className={`w-1/12`}>
                        <img
                            src={PencilIcon}
                            alt={'edit setting button'}
                            className={`w-6 h-6 rounded-full p-2 bg-indigo-6 cursor-pointer`}
                            onClick={() => openEdit()}
                        />
                    </div>
                </div>

                <div className={'mt-3 flex items-center justify-between'}>
                    <div className={`w-11/12`}>
                        <Header6 textAlign={'left'} color={'indigo-100'}>{t('haccp.settings.measurePerDay')}</Header6>
                        <Header6 textAlign={'left'} color={'text-onyx-87'}>
                            {rule && sensor
                                ? sensor.sensorId === editedSensorId
                                    ? editedSensorMeasurementTimes.length
                                    : rule.sample_hours.length
                                : 0}
                        </Header6>
                    </div>
                    <div className={`w-1/12`}>
                        <img
                            src={PencilIcon}
                            alt={'edit setting button'}
                            className={`w-6 h-6 rounded-full p-2 bg-indigo-6 cursor-pointer`}
                            onClick={() => openEdit()}
                        />
                    </div>
                </div>

                <div className={'mt-3 flex items-center justify-between'}>

                    <div className={`w-11/12`}>
                        <Header6 textAlign={'left'} color={'indigo-100'}>{t('haccp.settings.measureTimes')}</Header6>
                        <Header6 textAlign={'left'} color={'text-onyx-87'}>
                            {rule && sensor
                                ? sensor.sensorId === editedSensorId
                                    ? editedSensorMeasurementTimes.toString()
                                    : rule.sample_hours.toString()
                                : '/'}
                        </Header6>
                    </div>
                    <div className={`w-1/12`}>
                        <img
                            src={PencilIcon}
                            alt={'edit setting button'}
                            className={`w-6 h-6 rounded-full p-2 bg-indigo-6 cursor-pointer`}
                            onClick={() => openEdit()}
                        />
                    </div>
                </div>

                <div className={'mt-3 mb-8 flex items-center justify-between'}>
                    <div className={`w-11/12`}>
                        <Header6 textAlign={'left'} color={'indigo-100'}>{t('haccp.settings.thresholds')}</Header6>
                        <Header6 textAlign={'left'} color={'text-onyx-87'}>
                            {rule && sensor
                                ? sensor.sensorId === editedSensorId
                                    ? editedSensorThreshold[0]
                                    : rule.threshold[0]
                                : '/'} — {rule && sensor
                                    ? sensor.sensorId === editedSensorId
                                        ? editedSensorThreshold[1]
                                        : rule.threshold[1]
                                    : '/'} °C
                        </Header6>
                    </div>
                    <div className={`w-1/12`}>
                        <img
                            src={PencilIcon}
                            alt={'edit setting button'}
                            className={`w-6 h-6 rounded-full p-2 bg-indigo-6 cursor-pointer`}
                            onClick={() => openEdit()}
                        />
                    </div>
                </div>

                <Header4 textAlign={'left'} color={'indigo-100'}>{t('haccp.settings.techDetails')}</Header4>
                <div className={`flex mt-8 justify-between`}>
                    <div className={'w-2/3'}>
                        <Header6 textAlign={'left'} color={'indigo-100'}>ID</Header6>
                        <Header6 textAlign={'left'} color={'text-onyx-87'}>{rule ? rule.sensor_id : '/'}</Header6>
                    </div>

                    <div className={'w-1/3'}>
                        <Header6 textAlign={'left'} color={'indigo-100'}>{t('haccp.settings.battery')}</Header6>
                        <Header6 textAlign={'left'} color={'text-onyx-87'}>3.4 V</Header6>
                    </div>
                </div>

                <div className={'mt-3'}>
                    <Header6 textAlign={'left'} color={'indigo-100'}>{t('haccp.settings.lastData')}</Header6>
                    <Header6 textAlign={'left'} color={'text-onyx-87'}>{lastMeasurement ? moment(lastMeasurement.time).format('DD MMM YYYY hh:mm:ss') : '/'}</Header6>
                </div>

            </div>
        </div>
    )
}

export default HaccpSettings;