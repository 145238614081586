import React from 'react';
import HeaderCell from './HeaderCell'

const TableHeader = ({ headers, row, size, sign, onOrdering, ordering, withSensor }) => {

    if (size === 'small') {
        if(withSensor===true){
            return(
               <HeaderCell value={row['Sensor']} color={'header-small'} opacity={87} />
            )
        } else {
        return (
            <tr>
                <HeaderCell value={row[sign]} color={'header-small'} opacity={87} />
                <th></th>
            </tr>
        )
        }
    } else {
        return (
            <tr className={'flex justify-between w-full'}>
                {headers.map((header, index) => (
                    <HeaderCell value={header} key={`header_cell_${index}`} onOrdering={onOrdering} ordering={ordering} />
                ))}
            </tr>
        )
    }

}

export default TableHeader;