import React from 'react';
import DataTile from './DataTile';

const HeatMapRow = ({ elements, lastRow, threshold }) => {
    if (lastRow) {
        return (
            <tr>
                {elements.map((row, index) => (
                    <React.Fragment key={`data_tile_${index}`}>
                        <DataTile value={row} index={index} lastRow={lastRow} />
                    </React.Fragment>
                ))}
            </tr>
        )
    } else {
        return (
            <tr>
                {elements.map((row, index) => (
                    <React.Fragment key={`data_tile_${index}`}>
                        <DataTile value={row} index={index} threshold={threshold} />
                    </React.Fragment>
                ))}
            </tr>
        )
    }
}

export default HeatMapRow;
