import React, { useState, useEffect } from 'react';

//Components
import HeatMap from '../components/charts/HeatMap/HeatMap';
import Table from '../components/table/Table';

//Elements
import Header4 from '../elements/Header4';
import Header6 from '../elements/Header6';

//Assets
import SettingsButton from '../assets/icons/ellipses.svg';

//Services
import { getAllSensors } from '../services/location';
import { getRuleBySensorId, getMeasurementListByMonth } from '../services/haccp';
import { prepareMeasurementTableHeatMapData, newPrepareHeatmap } from '../services/heatmap';

const Haccp2Page = () => {
    //State
    const [availableSensors, setAvailableSensors] = useState([]);

    const heatMapTableHeaders = ['Date', 'Time', 'Alarm Type', 'Comment'];

    //Effect 
    useEffect(() => {
        getAllSensors().then(res => {
            if (res.length > 0) {
                let tempSensors = [];
                res.map(async (sensor, index) => {
                    const sensorList = await getMeasurementListByMonth(sensor.sensor_id, 10, 2020)
                    tempSensors.push(
                        {
                            id: sensor.id,
                            value: sensor.id,
                            label: sensor.name,
                            companyId: sensor.company_id,
                            group: sensor.location_group,
                            location: sensor.location,
                            active: sensor.is_active,
                            sensorId: sensor.sensor_id,
                            rule: await getRuleBySensorId(sensor.sensor_id),
                            preparedMeasurements: await newPrepareHeatmap(sensorList),
                            preparedMeasurementsTable: (await prepareMeasurementTableHeatMapData(sensorList)).slice(0, 5)
                            // preparedMeasurementsTable: await prepareMeasurementTableHeatMapData(sensorList)
                        }
                    );
                    if (index === res.length - 1) {
                        setAvailableSensors(tempSensors);
                    }
                })
            }
        }).catch(e => {
            console.log(e);
        });
    }, []);

    return (
        <div className={`py-10 px-16 sm:py-4 sm:px-4 w-full`} style={{ backgroundColor: '#fdfdfe' }}>
            {availableSensors.length > 0 ?
                availableSensors.map((sens, index) => (

                    <div key={`sensor_${index}`} className={`bg-text-white p-12 shadow mt-10 rounded-lg`}>
                        <img
                            src={SettingsButton}
                            alt={'Addittional settings button'}
                            className={`w-6 h-6 p-1 rounded-full bg-indigo-6 float-right cursor-pointer`}
                            onClick={() => alert('settings')}
                        />

                        <Header4 textAlign={'left'} color={'indigo-100'}>{sens.label}</Header4>
                        <Header6 textAlign={'left'} color={'text-onyx opacity-60 mt-1'}>
                            {sens.location.name}
                            {sens.rule ?
                                ` / Threshold: ${sens.rule.threshold[0]} — ${sens.rule.threshold[1]}°`
                                : false
                            }
                        </Header6>
                        <HeatMap sampleHours={sens.rule.sample_hours} measurement={sens.preparedMeasurements} threshold={sens.rule.threshold} month={10} year={2020} />

                        <Header4 textAlign={'left mt-12'} color={'indigo-100'}>Comments</Header4>
                        <Table headers={heatMapTableHeaders} rows={sens.preparedMeasurementsTable} sign={"Sensor"} size={'big'}></Table>

                    </div>
                ))
                : false
            }

        </div>
    )
}

export default Haccp2Page;
