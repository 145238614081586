import React, { useEffect, useState } from 'react';
import HeatMapTable from './HeatMapTable';

const emptyObject = { value: null, comment: null, time: null, date: null, hours: null, alarm_type: null, company_id: null, location: null, group: null, sensor: null, id: null, rule: null }

const HeatMap = ({ measurement, month, year, sampleHours, threshold }) => {
    //State
    const [heatmapTable, setHeatmapTable] = useState([[]]);

    //Effect
    useEffect(() => {
        if (sampleHours) {
            const days = new Date(year, month, 0).getDate()+1;

            generateEmptyHeatmap(days)
                .then(res => {
                    //setHeatmapTable(res);
                    fillHeatmap(res)
                        .then(res => {
                            setHeatmapTable(res);
                        })
                        .catch(e => console.log(e))
                })
                .catch(e => console.log(e))
        }
        // eslint-disable-next-line
    }, [sampleHours, measurement, month, year]);

    async function generateEmptyHeatmap(days) {
        return new Promise((resolve, reject) => {
            if (sampleHours && sampleHours.length > 0) {
                let tempMatrix = [];

                for (let i = 0; i <= sampleHours.length; i++) {
                    let tempRow = [];
                    if (i === sampleHours.length) { //Last row
                        for (let j = 0; j < days; j++) {
                            if (j === 0) {
                                tempRow.push({ ...emptyObject, value: 'Date' });
                            }
                            else {
                                tempRow.push({ ...emptyObject, value: j });
                            }
                        }
                    }
                    else { //Other rows
                        for (let j = 0; j < days; j++) {
                            if (j === 0) {
                                tempRow.push({ ...emptyObject, value: sampleHours[i] });
                            }
                            else {
                                tempRow.push({ ...emptyObject });
                            }
                        }
                    }
                    tempMatrix.push(tempRow);
                }

                resolve(tempMatrix);
            }
            else reject('No sample hours.');
        })
    }

    async function fillHeatmap(emptyHeatmap) {
        return new Promise((resolve, reject) => {
            let tempMatrix = emptyHeatmap;

            measurement.map((item) => {
                // replace "15:17" -> "15:00"
                const indexOfItem = sampleHours.indexOf(item.hours.replace(/..$/, "00"))

                if (tempMatrix[indexOfItem] != null &&
                    tempMatrix[indexOfItem][item.time.day] != null &&
                    tempMatrix[indexOfItem][item.time.day].value != null &&
                    item.temperature == null ) {
                    // prevent override of good 'value' key ( not null )
                    return item
                }

                if (indexOfItem !== -1) { 
                    tempMatrix[indexOfItem][item.time.day] = {
                        value: item.temperature ? Math.round(parseFloat(item.temperature)) : null,
                        comment: item.comment,
                        time: item.hours,
                        date: item.date,
                        hours: item.hours,
                        alarm_type: item.alarm_type,
                        company_id: item.company_id,
                        location: item.location,
                        group: item.group,
                        sensor: item.sensor,
                        id: item.id,
                        rule: item.rule
                    }
                }

                return item
            })
            tempMatrix ? resolve(tempMatrix) : reject('No matrix generated');
        })

    }

    if (measurement !== null) {
        return (
            <div className={'my-10 overflow-x-scroll'}>
                <HeatMapTable measurements={heatmapTable} threshold={threshold} />
            </div>
        )
    } else { return null }
}

export default HeatMap;
